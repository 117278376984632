



const FourZeroFour = () => {


    return (
        <div className="flex justify-center items-center bg-primary-600 w-full">
            <img className="max-sm:hidden sm:hidden flex h-screen" src="/images/404-desktop-100.jpg" alt="not found" />
            <img className="max-sm:flex sm:flex hidden h-screen" src="/images/404-mobile-100.jpg" alt="not found" />
        </div>
    );
}

export default FourZeroFour;