import { useMutation } from "@tanstack/react-query";
import { PROFILE } from "../../api/apiClient";
import { axiosPrivate } from "../../api/axios";
import { useEffect, useState } from "react";
import { sellerHeader } from "../../animation/RoutesAnimations";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import animationData from "../../../src/jsons/in-development.json";
import BeatLoader from "react-spinners/BeatLoader";
import './bordercss.css';

const InDevelopment = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        renderer: "svg",
      };

    return (
    <motion.div
    variants={sellerHeader}
    initial="initial"
    animate="final"
    className="overflow-x-hidden min-h-screen"
    >
        <div className="flex flex-col h-screen w-full items-center justify-center">
             <div className="flex w-2/3 justify-center items-center rounded-xl p-[1.5px] example-2 -z-10">
             <div className="flex max-sm:flex-col sm:flex-col md:flex-row w-full justify-center items-center rounded-xl gap-2 p-10 bg-white z-10">
             <div className="flex flex-col w-[60px] items-center justify-center ml-4 cursor-pointer">
                    <Lottie isClickToPauseDisabled={true} options={defaultOptions} height={60} width={60}/>
                </div>
                <div className="flex gap-2 items-center justify-centers">
                <p className="text-primary-800 max-sm:text-HeadLine/24/ExtraBold text-HeadLine/36/Bold">در حال توسعه </p>
                <div className="max-sm:hidden">
                <BeatLoader
                    color={'#023E8A'}
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>

                </div>

            </div>
             </div>
        </div>
    </motion.div>
    )}

    export default InDevelopment;

