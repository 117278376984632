import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
  modalHeightVariants,
  modalVariants,
} from "../../../animation/RoutesAnimations";
import { useEffect, useState } from "react";
import { axiosPrivate } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { PROFILE } from "../../../api/apiClient";
import ButtonComponent from "../../../components/elements/ButtonComponent";
import { notEmptyAndPersianField, notEmptyField } from "../../../utils/customSchemas";
import customZ from "../../../utils/setZodCustomMessages";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputComponent from "../../elements/InputComponent";
import ControlledSelectElement from "../../elements/ControlledSelectElement";
import Select from "react-dropdown-select";
import MapScreen from "../../map/Map";

interface EditProfileSchema {
    name: string;
    address: string;
    work_name: any;
  }


const  EditProfile = ({
  onClose,
  selectRef,
  mutation,
  inpLat,
  inpLng
}: any) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectData, setSelectData] = useState<any>(undefined);
  const [map, setMap] = useState<boolean>(true);
  const [lng, setLng] = useState(inpLng);
  const [lat, setLat] = useState(inpLat);
  const editProfileSchema = customZ.object({
    name: notEmptyField,
    address: notEmptyAndPersianField,
    work_name: notEmptyField
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<EditProfileSchema>({
    resolver: (data, context, options) => {
      return zodResolver(editProfileSchema)(data, context, options);
    },
  });

  async function EditProfile (data: any) {
    setLoading(true);
    const formData = {...data, lat, lng}
    try {
        const response = await axiosPrivate.put(
        PROFILE, formData,     {withCredentials: true,}
      );
      if (response?.status >= 200 && response?.status < 300) {
        mutation.mutate();
        onClose()
      }
      setLoading(false);
    } catch (error) {
      if ((error as any)?.response) {
        const responseStatus: any = (error as any).response?.status;
        if (responseStatus === 500) {
            console.log('server 500 error')
        }
      } else {
        console.log('server error')
      }
    } finally {
      setLoading(false)
    }
  }
  
  const workNameData = [
    {name: 'آرایشگاه مردانه'},
    {name: 'آرایشگاه زنانه'},
    {name: 'مطب دکتر'},
    {name: 'تعمیرات خودرو'},
    {name: 'ناخن کار'},
    {name: 'مژه کار'},
    {name: 'تتو'},
    {name: 'رنگ و کراتین مو'},
    {name: 'کوتاهی مو'},
    {name: 'فیشیال'},
    {name: 'میکاب عروس'},
    {name: 'ابرو'},
  ]

  useEffect(() => {
    if (selectData)
      setValue('work_name', selectData[0]?.name)
    }, [selectData])

  return ReactDOM.createPortal(
    <>
      <motion.div
        variants={modalVariants}
        initial="initial"
        animate="final"
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-gray-500 bg-opacity-50"
      >
        <motion.div
          layout
          variants={modalHeightVariants}
          initial="initial"
          animate="final"
          className={`relative w-[400px] bg-white rounded-lg shadow pb-4 px-4 `}
        >
          <motion.div
            layout={"position"}
            initial="initial"
            animate="final"
            className="relative"
          >
            <div ref={selectRef} className="flex flex-col w-full">
              <div className=" flex items-end justify-end rounded-t pt-2">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                >
                  <img src="/icons/x.svg" alt="icon" width={20} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* code here */}
              <div className="flex w-full justify-center items-center mb-6">
                <p className="text-gray-900 text-BodyText/16/Bold">اصلاح پروفایل</p>
            </div>
            {map ? (<>
            <div className="flex flex-col w-full justify-center items-center gap-6">
              <div className="gap-1 items-start">
              <p className="text-gray-900 text-Body-OneLine/14/Regular">مشخص کردن موقعیت مکانی کسب و کار:</p>
            <MapScreen setLng={setLng} setLat={setLat} lng={lng} lat={lat} />
              </div>

            <ButtonComponent 
            onClick={() => setMap(false)}
            className="mt-12"
            >مرحله بعد</ButtonComponent>
            </div>
            
            </>) : 
            (<>
                        <form onSubmit={handleSubmit(EditProfile)} className="flex flex-col w-full justify-center items-center p-4">
                <div className="flex flex-col w-[225px] mb-4 gap-1">
                  <p className="text-gray-900 text-Body-OneLine/14/Regular">نوع خدمت خود را انتخاب کنید:</p>
                  <Select
                    style={{width: '100%', borderRadius: '6px', height: '40px', padding: '12px', zIndex: 40}}
                    options={workNameData}
                    placeholder="انتخاب"
                    required
                    labelField="name"
                    valueField="name"
                    onChange={(values) => setSelectData(values)} values={[]}          
                    /> 
                </div>
                <InputComponent 
                    name='name'
                    label="نام کسب و کار"
                    type="text"
                    errors={errors?.name}
                    control={control}
                />

                <InputComponent 
                    name='address'
                    label="آدرس"
                    type="text"
                    errors={errors?.address}
                    control={control}

                />
                

            <div className="flex flex-row gap-4 justify-center items-center">
                <ButtonComponent 
                  hasIcon
                  onClick={onClose}
                  variant="outlined"
                  icon={
                    <img className=" w-4 h-4" src="/icons/x.svg" alt="not found" />
                  }
                  className=""
                  >لغو</ButtonComponent>
                  <ButtonComponent 
                  hasIcon
                  isClicked={loading}
                  type="submit"
                  variant="outlined"
                  icon={
                    <img className=" w-6 h-6" src="/icons/tik.svg" alt="not found" />
                  }
                  className=""
                  >ذخیره</ButtonComponent>
           </div>
            </form>
            </>)}

            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>,
    document.getElementById("modal-root")!
  );
};

export default EditProfile;
