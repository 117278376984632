import { type CustomDetector } from "i18next-browser-languagedetector";

const myLangDetector: CustomDetector = {
  name: "myLangDetector",
  lookup: () => {
    const hostname = document.location.hostname;
    if (hostname === "localhost") return "fa";
    return "en";
  },
};

export default myLangDetector;
