import { sellerHeader } from "../../animation/RoutesAnimations";
import { motion } from "framer-motion";
import { useState } from 'react'
import useAddComma from "../hooks/useAddComma";
import Login from "./Login";
import Signup from "./Signup";
import { Helmet } from "react-helmet";


const Auth = () => {


  const addCama = useAddComma();
  const [signupMode, setSignupMode] = useState(false);



  function loginHandler () {

  }

  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className="overflow-x-hidden min-h-screen "
    >
            <Helmet>
                <title>ورود رزرو شو</title>
                <meta name="description" content="صفحه ورود رزرو شو" />
                <link rel="canonical" href="https://rezerv-sho.ir/roles" />
            </Helmet>
        <div>
        <div className="flex w-full bg-gradient-to-br from-primary-400 to-primary-800 h-screen items-center justify-center">
            <div className="flex flex-col items-center justify-centerv max-sm:w-11/12 sm:w-4/6 lg:w-2/6 bg-white my-40 rounded-2xl">
            <img width={48} className="mt-4" src="/icons/rezervsho-logo.svg" alt="not found" />
            {!signupMode ? 
            (<Login setSignupMode={setSignupMode} />) 
            : 
            (<Signup setSignupMode={setSignupMode} />)}

            </div>
        </div>
        </div>
    </motion.div>
  );
};

export default Auth;
