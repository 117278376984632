import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
  modalHeightVariants,
  modalVariants,
} from "../../../animation/RoutesAnimations";
import customZ from "../../../utils/setZodCustomMessages";
import { useState } from "react";
import { axiosPrivate } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { ORDER_INITIAL_APPROVAL } from "../../../api/apiClient";
import ButtonComponent from "../../../components/elements/ButtonComponent";
import { useForm } from "react-hook-form";
import { WORK_TYPE } from "../../../api/apiClient";
import { zodResolver } from "@hookform/resolvers/zod";
import InputComponent from "../../elements/InputComponent";
import { notEmptyAndPersianField, onlyNumberSchema, priceSchema } from "../../../utils/customSchemas";

interface WorkTypeSchemas {
    name: string;
    price: number;
    time: number;
  }

const  WorkAddPopup = ({
  onClose,
  selectRef,
  mutation,
}: any) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false)
  
  function addCama(value: any) {
    var valueString = String(Math.round(value));
    var reversed = valueString.split("").reverse();
    for (let i = 3; i < reversed.length; i += 4) {
        reversed.splice(i, 0, ",");
    }
    return reversed.reverse().join("");
}

const workTypeSchema = customZ.object({
    name: notEmptyAndPersianField,
    price: priceSchema,
    time: onlyNumberSchema,
  });

const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<WorkTypeSchemas>({
    resolver: (data, context, options) => {
      return zodResolver(workTypeSchema)(data, context, options);
    },
  });

  async function SendWorkType (data: any) {
    setLoading(true);

    try {

      const response = await axiosPrivate.post(
        WORK_TYPE(), data,     {withCredentials: true,}
      );
      if (response?.status >= 200 && response?.status < 300) {
        mutation.mutate();
        onClose()
      }
      setLoading(false);
    } catch (error) {
      if ((error as any)?.response) {
        const responseStatus: any = (error as any).response?.status;
        if (responseStatus === 500) {
            console.log('server 500 error')
        }
      } else {
        console.log('server error')
      }
    } finally {
      setLoading(false)
    }
  }

  return ReactDOM.createPortal(
    <>
      <motion.div
        variants={modalVariants}
        initial="initial"
        animate="final"
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-gray-500 bg-opacity-50"
      >
        <motion.div
          layout
          
          variants={modalHeightVariants}
          initial="initial"
          animate="final"
          className={`relative w-[400px] overflow-hidden bg-white rounded-lg shadow pb-4 px-4 `}
        >
          <motion.div
            layout={"position"}
            initial="initial"
            animate="final"
            className="relative"
          >
            <div ref={selectRef} className="flex flex-col w-full">
              <div className=" flex items-end justify-end rounded-t pt-2">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                >
                  <img src="/icons/x.svg" alt="icon" width={20} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* code here */}
              
              
            <div className="flex w-full justify-start items-center">
                <p className="text-gray-900 text-BodyText/16/Bold">افزودن خدمت</p>
            </div>
            <form onSubmit={handleSubmit(SendWorkType)} className="flex flex-col w-full justify-center items-center p-4">
                <InputComponent 
                    name='name'
                    label="عنوان خدمت"
                    type="text"
                    errors={errors?.name}
                    control={control}
                />

                <InputComponent 
                    name='price'
                    label="مبلغ"
                    type="tel"
                    errors={errors?.price}
                    control={control}
                    hasLeftIcon
                    leftIcon={<img src="/icons/tooman.svg" alt="not found" />}

                />

                <InputComponent 
                    name='time'
                    label="زمان"
                    type="tel"
                    errors={errors?.time}
                    control={control}
                    hasLeftIcon
                    leftIcon={<img className="w-7"  src="/icons/minute.svg" alt="not found" />}
                />
            <div className="flex flex-row gap-4 justify-center items-center">
                <ButtonComponent 
                  hasIcon
                  onClick={onClose}
                  variant="outlined"
                  icon={
                    <img className=" w-4 h-4" src="/icons/x.svg" alt="not found" />
                  }
                  className=""
                  >لغو</ButtonComponent>
                  <ButtonComponent 
                  hasIcon
                  isClicked={loading}
                  type="submit"
                  variant="outlined"
                  icon={
                    <img className=" w-6 h-6" src="/icons/tik.svg" alt="not found" />
                  }
                  className=""
                  >ذخیره</ButtonComponent>
           </div>
            </form>


            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>,
    document.getElementById("modal-root")!
  );
};

export default WorkAddPopup;
