import { sellerHeader } from "../../animation/RoutesAnimations";
import { delay, motion } from "framer-motion";
import { useEffect, useRef, useState } from 'react'
import React from "react";
import { Tab, Tabs } from "../../components/user-dashboard-tabs/Tabs";
import useAddComma from "../hooks/useAddComma";
import InputComponent from "../elements/InputComponent";
import ButtonComponent from "../elements/ButtonComponent";
import animationData from "../../../src/jsons/Loading333.json";
import QRCode from "react-qr-code";
import WorkAddPopup from "./popups/WorkAddPopup";
import { useMutation } from "@tanstack/react-query";
import { PROFILE, WORK_TYPE, WALLET, TIME_REQUEST, ACCEPT_REJECT } from "../../api/apiClient";
import { axiosPrivate } from "../../api/axios";
import Lottie from "react-lottie";
import Profile from "./Profile";
import StoreSettings from "./StoreSetting"
import CustomerTimeTable from "./CustomerTimeTable";
import { convertDateTimeToJalali } from "../../utils/useDateTimeConvertToJalali";
import '../../../src/index.css'
import Wallet from "./Wallet";
import DeleteWorkType from "./popups/DeleteWorkType";
import GridLoader from "react-spinners/GridLoader";
import { toast } from "react-toastify";

const Customer = ({ timeTableRefresh }: any) => {


  const addCama = useAddComma();
  const [popup, setPopup] = useState(false);
  const [deleteWorkTypepopup, setDeleteWorkTypepopup] = useState(false);
  const [hover, setHover] = useState(false);
  const [qrAdress, setQrAdress] = useState<string>('https://google.com/');
  const [workTypeData, setWorkTypeData] = useState<any>(undefined);
  const [loadingWorkTypeData, setLoadingWorkTypeData] = useState<boolean>(false);
  const [profileData, setPofileData] = useState<any>(undefined);
  const [profileDataLoading, setProfileDataLoading] = useState<boolean>(false);
  const [walletDataLoading, setWalletDataLoading] = useState<boolean>(false);
  const [choosedItem, setChoosedItem] = useState<any>(undefined)
  const [wallet, setWallet] = useState<any>(undefined);

  const getWallet = () =>
    axiosPrivate
      .get(
        WALLET,

        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.wallet);

  const walletMutate = useMutation({
    mutationFn: getWallet,
    onSuccess(data, variables, context) {
      setWallet(data);
      setWalletDataLoading(false);
    },
    onMutate() {
      setWalletDataLoading(true);
    },
    onError() {
      setWalletDataLoading(false);
    },
  });

  useEffect(() => {
    walletMutate.mutate();
  }, [])

  const getProfile = () =>
    axiosPrivate
      .get(
        PROFILE,

        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.customer_profile);

  const profileMutate = useMutation({
    mutationFn: getProfile,
    onSuccess(data, variables, context) {
      setPofileData(data);
      setQrAdress('https://rezerv-sho.ir/user/' + data?.customer_uuid)
      setProfileDataLoading(false);
    },
    onMutate() {
      setProfileDataLoading(true);
    },
    onError() {
      setProfileDataLoading(false);
    },
  });

  useEffect(() => {
    profileMutate.mutate();
  }, [])

  function onClose() {
    setPopup(false);
    setDeleteWorkTypepopup(false);
  }

  function minToHour(input: any) {

    let pureMin = Number(input)
    let min = String(pureMin % 60)
    let hour = String(Math.floor(pureMin / 60,))

    if (hour === '0') {
      return min + ' دقیقه'
    } else if (min === '0') {
      return hour + ' ساعت'
    } else {
      return hour + ' ساعت ' + min + ' دقیقه'
    }
  }


  const getWorkTypes = () =>
    axiosPrivate
      .get(
        WORK_TYPE(),

        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.work_types);

  const workTypeMutate = useMutation({
    mutationFn: getWorkTypes,
    onSuccess(data, variables, context) {
      setWorkTypeData(data);
      setLoadingWorkTypeData(false);
    },
    onMutate() {
      setLoadingWorkTypeData(true);
    },
    onError() {
      setLoadingWorkTypeData(false);
    },
  });

  useEffect(() => {
    workTypeMutate.mutate();
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };


  async function deleteWorkType(id: string) {
    setLoadingWorkTypeData(true);

    try {

      const response = await axiosPrivate.delete(
        WORK_TYPE(undefined, id), { withCredentials: true, }
      );
      if (response?.status >= 200 && response?.status < 300) {
        setWorkTypeData(response?.data?.work_types)
      }
      setLoadingWorkTypeData(false);
    } catch (error) {
      if ((error as any)?.response) {
        const responseStatus: any = (error as any).response?.status;
        if (responseStatus === 500) {
          console.log('server 500 error')
        }
      } else {
        console.log('server error')
      }
    } finally {
      setLoadingWorkTypeData(false)
    }
  }


  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className="overflow-x-hidden"
    >


      <div className="flex w-full  overflow-y-visible flex-col relative items-center justify-center">

        <div className="max-sm:w-11/12 sm:w-11/12 lg:w-2/3 mt-6 justify-center items-center mb-14">
          {/* قسمت بارکد که ابوط نوشت */}
          <div className="flex max-sm:flex-col-reverse sm:flex-col-reverse lg:flex-row w-full  bg-gray-100 p-1 rounded-3xl">
            <div className="flex flex-col max-sm:w-5/5 sm:w-full lg:w-3/5 justify-between max-sm:pt-0.5 sm:pt-0.5 lg:pt-4 items-start">
              <p className="text-BodyText/14/Medium text-gray-600 px-8 py-2 max-sm:text-center sm:text-center lg:text-right">این QRcode اختصاصی برای شما ساخته شده است ومشتریان شما از طریق اسکن کردن آن میتوانند به صفحه‌‌ی پروفایل شما دسترسی داشته باشند!</p>
              <div className="flex max-sm:flex-col sm:flex-col lg:flex-row w-full gap-4 px-7 py-4 z-10">
                <ButtonComponent
                  hasIcon
                  // disabled
                  variant="outlined"
                  icon={
                    <img className=" w-4 h-4" src="/icons/pen-blue.svg" alt="not found" />
                  }
                  disabled
                  className=""
                >درخواست طراحی</ButtonComponent>
                <ButtonComponent
                  hasIcon
                  // disabled
                  variant="outlined"
                  icon={
                    <img className=" w-4 h-4" src="/icons/link-blue.svg" alt="not found" />
                  }
                  className=""
                  onClick={() => { navigator.clipboard.writeText(qrAdress); }}
                >کپی کردن لینک</ButtonComponent>
                <ButtonComponent
                  hasIcon
                  // disabled
                  variant="outlined"
                  icon={
                    <img className=" w-4 h-4" src="/icons/print-blue.svg" alt="not found" />
                  }
                  className=""
                >چاپ کردن</ButtonComponent>
              </div>
            </div>
            <div className="w-[1px] max-sm:hidden sm:hidden lg:flex my-5 bg-gray-500 rounded-3xl opacity-40"></div>
            <div className="flex flex-col justify-center items-center max-sm:w-4/5 sm:w-3/5 lg:w-2/5 h-[256px] m-auto z-10">
              {profileMutate.isPending ? (
                        <GridLoader
                        color={'#e8c400'}
                        loading={true}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className="my-32"
                    />
              ) : (
                <>
              <QRCode
                onClick={() => { navigator.clipboard.writeText(qrAdress); toast.success('کپی شد') }}
                className="cursor-pointer rounded-md"
                size={128}
                bgColor="#e5e7eb"
                style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                value={qrAdress}
                viewBox={`0 0 128 128`}
              />
              <p className="text-gray-500 text-HelpText/11/Light mt-2">برای کپی کردن لینک روی بارکد کلیک کنید</p>
            
                </>
              )}

            </div>
          </div>
          <Tabs>
            <Tab label="جدول زمان بندی" icon={<div className="flex w-full items-center justify-center"><img className="w-5 h-5" src="/icons/calendar-clock.svg" alt="not found" /></div>}>
              <CustomerTimeTable walletMutate={walletMutate} workTypeData={workTypeData} profileData={profileData} timeTableRefresh={timeTableRefresh} />
            </Tab>
            <Tab label="تنطیمات کسب و کار" icon={<div className="flex w-full items-center justify-center"><img className="w-5 h-5" src="/icons/settings.svg" alt="not found" /></div>}>
              <p className="text-gray-900 text-BodyText/16/Bold">تنطیمات کسب و کار</p>
              <div className="flex flex-col gap-4 items-start mr-4">

                <div className="flex flex-col">
                  <div className=" text-gray-900 text-BodyText/16/Medium flex items-start">
                    تنطیمات خدمات
                  </div>
                  <div className="flex justify-end ml-2">
                    <img onClick={() => { setPopup(true) }} className="max-sm:w-[20px] sm:w-[20px] lg:w-[20px] cursor-pointer max-sm:mb-2 sm:mb-2 lg:mb-4" src="icons\plus-outline.svg" alt="plus-outline icon" />
                  </div>
                  <div className="flex flex-col justify-start items-center h-[200px] overflow-x-hidden min-w-[250px] max-w-[400px] bg-primary-100 rounded-lg mr-4 gap-2 p-2">
                    {loadingWorkTypeData ? (<>
                      <div className="flex flex-col w-full justify-center p-10">
                        <Lottie options={defaultOptions} height={100} width={100} />
                      </div>
                    </>) : (<>
                      {workTypeData?.map((item: any, index: number) => (
                        <>
                          <div className="flex flex-row bg-white w-full h-[40px] rounded-xl">
                            <div className="flex flex-col w-5/6">
                              <div className="flex mr-4 justify-start">
                                <p className="text-gray-800 text-Title/14/Regular">{item.name}</p>
                              </div>
                              <div className="flex mr-6 justify-start">
                                <p className="text-gray-600 text-Body-OneLine/12/Light">({addCama(item.price)} تومان و {minToHour(item.time)})</p>
                              </div>
                            </div>
                            <div className="flex flex-row h-full w-1/6 justify-center items-center">
                              <div className="flex justify-center ml-2">
                                <img onClick={() => {setDeleteWorkTypepopup(true); setChoosedItem(item?.id)}} className="w-[16px] h-[16px] cursor-pointer" src="icons\trash-red.svg" alt="trash icon" />
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </>)}

                  </div>
                </div>

                <div className="bg-gray-300 w-full h-[1px]"></div>
                <StoreSettings />

              </div>
            </Tab>
            <Tab label="گالری" icon={<div className="flex w-full items-center justify-center"><img className="w-5 h-5" src="/icons/gallery.svg" alt="not found" /></div>}>
              <p className="text-gray-900 text-BodyText/16/Bold">گالری</p>
              <div className="flex max-sm:flex-col sm:flex-col lg:flex-row bg-primary-200 rounded-2xl w-[50%] justify-center items-center gap-2 p-2 m-auto">

                <img className="w-7 h-7" src="/icons/setting-blue.svg" alt="not found" />
                <p className="text-BodyText/14/Regular text-gray-600 text-center">این صفحه درحال توسعه میباشد!</p>
              </div>
              {/* <div className="flex flex-col bg-gray-100 w-[600px] h-[400px] rounded-3xl mr-4 px-10">
             <div className="flex flex-row w-full h-10 mt-2 gap-2 items-center border-b border-gray-200">
              <img src="/icons/plus-outline.svg" alt="plus-outline icon" className="w-[16px] h-[16px]"/>
              <p>افزودن تصویر</p>
             </div>
             <div  className="flex flex-row w-[550px] h-[300px] relative">
              <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} 
              className="flex w-1/2 h-50 ">

              
              <img className="rounded-md" src="/images/ThomasLean.png" alt="not found" />
              <div className={`flex ${hover ? (`visible`) : (`invisible`) } visible w-1/2 h-full  items-center justify-center absolute bg-red-300 z-10 opacity-40`}>

                <img className="w-6 h-6" src="/icons/trash-x.svg" alt="not found" />
              </div>
              </div>
             </div>
            </div> */}
            </Tab>
            <Tab label="کیف پول" icon={<div className="flex w-full items-center justify-center"><img className="w-5 h-5" src="/icons/wallet.svg" alt="not found" /></div>}>
              <p className="text-gray-900 text-BodyText/16/Bold">کیف پول</p>
              <Wallet data={wallet} />
            </Tab>
            <Tab label="پروفایل" icon={<div className="flex w-full items-center justify-center"><img className="w-5 h-5" src="/icons/user-tab.svg" alt="not found" /></div>}>
              <Profile loading={profileDataLoading} profileData={profileData} mutation={profileMutate} />
            </Tab>
          </Tabs>
        </div>

        {popup && <WorkAddPopup mutation={workTypeMutate} onClose={onClose} />}
        {deleteWorkTypepopup && <DeleteWorkType onClose={onClose} data={workTypeData} deleteFunction={() => {deleteWorkType(choosedItem); onClose()}} />}
      </div>

    </motion.div>
  );
};

export default Customer;
