import { Fragment, useEffect, useState, useRef } from "react";
import ButtonComponent from "../../../../components/elements/ButtonComponent";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import animationData from "../../../../../src/jsons/Loading333.json";
import { CustomAxiosRequestConfig, axiosPrivate } from "../../../../api/axios";
import {
  STORE_ORDER_STATE_LIST,
  CORE_CITY_NO_ID,
} from "../../../../api/apiClient";
import { useMutation } from "@tanstack/react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { CalenderIcon } from "../../../../components/Icons";
import moment from "jalali-moment";
import useAddComma from "../../../../components/hooks/useAddComma";
import { clickOutside } from "../../../../utils/clickOutside";
import { delay } from "framer-motion";
import Select from 'react-dropdown-select';
// complete one

const TableHeader = ({
workTypeData,
setTableTime,
fixed,
setInputDate,
user,
pb0,
setService,
setPrice
}: any) => {

  const [btnDataShow, setBtnDateShow] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<any>(undefined);
  const [dateTemp, setDateTemp] = useState<any>(undefined);
  const [selectData, setSelectData] = useState<any>(undefined);
  
  const addCama = useAddComma();
  // const getCity = () =>
  //   axiosPrivate
  //     .get(CORE_CITY_NO_ID, {
  //       headers: {
  //         // prettier-ignore
  //         "accept": "application/json",
  //         // prettier-ignore
  //         "Authorization": `Bearer ${access_token}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //       withCredentials: true,
  //       globalErrorHandler: false,
  //     } as CustomAxiosRequestConfig)
  //     .then((res) => res?.data?.data.result);

  // const cityJsonMutation = useMutation({
  //   mutationFn: () => getCity(),
  //   onSuccess(data, variables, context) {
  //     var sample = data;
  //     for (let i = 0; i < sample.length; i++) {
  //       sample[i]["symbol"] = sample[i].state;
  //       sample[i]["state"] = false;
  //     }

  //     setCityJson((prev: any) => sample);
  //   },
  // });

  // useEffect(() => {
  //   if (hasCityFilter) {
  //     cityJsonMutation.mutate();
  //   }
  // }, []);
  useEffect(() => {
    setInputDate(dateTemp)
  }, [dateTemp])
  useEffect(() => {
    if (selectData){
    setTableTime(selectData[0]?.time)
    setService(selectData[0]?.name)
    setPrice(selectData[0]?.price)

    }
  }, [selectData])


  return (
    <div className={`flex ${fixed ? `relative` : ``} w-full ${pb0 ? `pb-0` : ``} p-4 gap-2 bg-white rounded-tr-lg rounded-tl-lg`}>
      <div className={`flex flex-col ${fixed ? `fixed top-0 left-0 right-0` : ``} w-full items-center gap-2.5 justify-between`}>
        <div className="flex max-sm:flex-col sm:flex-col lg:flex-row gap-6 items-center">

          <div
            className={`flex max-sm:flex-col sm:flex-col lg:flex-row gap-2 max-sm:justify-start max-sm:items-start sm:justify-start sm:items-start lg:justify-center lg:items-center`}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFnsJalali}
              dateFormats={{ monthShort: "MMMM" }}
              localeText={{
                fieldMonthPlaceholder: () => "ماه",
                fieldDayPlaceholder: () => "روز",
                fieldYearPlaceholder: () => "سال",
              }}
            >
              <p className="text-gray-600 text-BodyText/16/Regular">انتخاب  تاریخ </p>
              <div className="flex flex-row gap-0  items-center">
                <DatePicker
                  value={dateValue && dateValue}
                  onChange={(date: any) => {
                    if (moment(date).format("YYYY-MM-DD") === "Invalid date") {
                      setDateTemp(undefined);
                    } else {
                      setDateTemp(moment(date).format("YYYY-MM-DD"));
                      setDateValue(date);
                    }
                  }
                  }
                  onViewChange={() => setBtnDateShow(true)}
                  slots={{
                    openPickerIcon: CalenderIcon,
                  }}
                  closeOnSelect={true}
                  slotProps={{
                    inputAdornment: {
                      position: "start",
                    },
                  }}
                  className="w-[150px]"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "44px",
                      border: "none",
                      borderRadius: "0.5rem",
                    },
                    "& .MuiDialogActions-root": {
                      bgcolor: "#F9FAFB !important",
                    },
                    "& .MuiInputBase-root.Mui-focused": {
                      bgcolor: "#F9FAFB !important",
                    },

                    "& MuiInputBase-root-MuiOutlinedInput-root": {
                      bgcolor: "red",
                    },
                  }}
                  views={["year", "month", "day"]}
                  openTo="year"
                />
              </div>
            </LocalizationProvider>{" "}
          </div>
          <div className="flex gap-1 max-sm:flex-col sm:flex-col lg:flex-row max-sm:justify-start max-sm:items-start sm:justify-start sm:items-start lg:justify-center lg:items-center">
          <p className="text-gray-600 text-BodyText/16/Regular">انتخاب خدمت</p>
          {user ? (
          <>
          {workTypeData !== undefined ? (
            <Select
            style={{width: '150px', borderRadius: '6px', height: '40px', padding: '12px'}}
              options={workTypeData}
              placeholder="انتخاب"
              labelField="name"
              valueField="time"
              onChange={(values) => setSelectData(values)} values={[]}          />) : null}
          </>
          ) : (
          <>
          {workTypeData !== undefined ? (<Select
            style={{width: '150px', borderRadius: '6px', height: '40px', padding: '12px'}}
              options={workTypeData}
              placeholder="انتخاب"
              labelField="name"
              valueField="time"
              onChange={(values) => setSelectData(values)} values={[]}          />) : null}
                
            </>
          )}
          </div>

        </div>
          <div className="flex w-full justify-center border-b border-gray-100 items-center">
          {(user && selectData) ? (
            <>
            <div className="flex gap-1 p-1">
              <p className="text-gray-900 text-BodyText/14/Medium">هزینه خدمت:</p>
              <p className="text-primary-900 text-BodyText/14/Bold">{addCama(selectData[0]?.price)}</p>
              <p className="text-gray-900 text-BodyText/14/Medium">تومان</p>
            </div>
            </>
          ) : null}
          </div>
      </div>
    </div>
  );
};
export default TableHeader;
