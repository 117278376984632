import { sellerHeader } from "../../animation/RoutesAnimations";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from 'react'
import React from "react";
import { Tab, Tabs } from "../../components/user-dashboard-tabs/Tabs";
import useAddComma from "../hooks/useAddComma";
import InputComponent from "../elements/InputComponent";
import ButtonComponent from "../elements/ButtonComponent";
import Footer from "../../provider/Footer";
import { axiosPrivate } from "../../api/axios";
import { PAYMENT } from "../../api/apiClient";
import GridLoader from "react-spinners/GridLoader";
import '../../components/Pages/bordercss.css';
import { convertDateTimeToJalali } from "../../utils/useDateTimeConvertToJalali";

const PaymentReport = () => {

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    const navigate = useNavigate();
    const addCama = useAddComma();
    const status = params.get('Status');
    const authority = params.get('Authority')
    const data = localStorage.getItem('time_request_formdata')
    const [loading, setLoading] = useState<boolean>(true);
    const store_id = localStorage.getItem('store_id');
    const user_phone_number = localStorage.getItem("user_phone_number");
    const user_fullname = localStorage.getItem("user_fullname");
    const time = localStorage.getItem("time");
    const time_unit = localStorage.getItem("time_unit");
    const name = localStorage.getItem("name");
    const store_name = localStorage.getItem("store_name");
    const inputDate = localStorage.getItem("inputDate");
    const [success, setSuccess] = useState<any>(undefined);

    function timeCalculator(time: any, time_unit: any) {
      var min_unit1 = time * (time_unit - 1)
      var hours1 = Math.floor(min_unit1 / 60)
      var minutes1 = min_unit1 % 60
      hours1 = hours1 + 6
      var min_unit2 = time * (time_unit)
      var hours2 = Math.floor(min_unit2 / 60)
      var minutes2 = min_unit2 % 60
      hours2 = hours2 + 6
      return `${hours1}:${minutes1} تا ${hours2}:${minutes2}`
    }

  async function CheckPayment (authority: any) {
    setLoading(true);
  
    try {
      const response = await axiosPrivate.get(
        PAYMENT(undefined, authority),     {withCredentials: true,}
      );
      if (response?.status >= 200 && response?.status < 300) {
        setLoading(false);
        setSuccess(true);  
  
      }
      setLoading(false);
    } catch (error) {
      setSuccess(false)
      if ((error as any)?.response) {
        const responseStatus: any = (error as any).response?.status;
        if (responseStatus === 500) {
            console.log('server 500 error')
        }
      } else {
        setSuccess(false)
        console.log('server error')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    CheckPayment(authority)
  }, [])

  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className="overflow-x-hidden min-h-screen "
    >
      <div className="flex flex-col bg-gradient-to-br from-yellow-600 to-yellow-300 w-full h-screen items-center justify-center py-32 gap-5">

     
      {loading ? (<>
        <GridLoader
                      color={'#002db4'}
                      loading={true}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      className="my-32"
                  />
      </>) : (<>
        <>
            {status === 'OK' && success ? (
                <div className=" flex flex-col max-sm:w-11/12 sm:w-11/12 lg:w-10/12 pt-16 pb-10 gap-5 justify-center items-center bg-white rounded-3xl">
                <img className="w-32 h-32" src="/icons/success.svg" alt="success" />
                <p className="text-[#13B501] max-sm:text-SubHeading/18/Bold sm:text-SubHeading/18/Bold lg:text-HeadLine/32/ExtraBold mt-4">عملیات شما با موفقیت انجام شد!</p>
                {time && time_unit && name && user_fullname && inputDate ? (
                <div className="flex w-[400px] justify-center items-center rounded-md p-[2px] example-1 z-10">
                <div className="flex flex-col w-[400px] justify-center gap-4 items-center p-2 rounded-md bg-yellow-50 z-20">
                  <img src="/icons/rezervsho-logo.svg" alt="not found" />
                  <p className="text-gray-800 text-BodyText/16/Medium text-center">وقت در تاریخ <span className="text-primary-800 text-BodyText/16/Bold">{convertDateTimeToJalali(String(inputDate))}</span> و زمان <span className="text-primary-800 text-BodyText/16/Bold">{timeCalculator(time, time_unit)}</span> در <span className="text-primary-800 text-BodyText/16/Bold">{store_name}</span> و برای <span className="text-primary-800 text-BodyText/16/Bold">{name}</span> به نام <span className="text-primary-800 text-BodyText/16/Bold">{user_fullname}</span> رزرو شد.</p>
                </div>
                </div>
                ) : null}

                {/* <div className="flex flex-row items-center justify-center gap-6 max-sm:w-full sm:w-full lg:w-2/6">
                    <p className="text-gray-500 text-BodyText/12/Regular">تا انتقال به سایت پذیرنده</p>
                    <p className="text-gray-800 text-BodyText/12/Bold">00:05</p>
                </div> */}
                <button disabled={loading} onClick={() => navigate('/user/'+ store_id)} className="bg-[#13B501] text-white text-BodyText/14/Bold rounded-md px-5 py-1 cursor-pointer">
                    تکمیل خرید
                </button>
                </div>
            ) : (
                <div className=" flex flex-col max-sm:w-11/12 sm:w-11/12 lg:w-10/12 pt-16 pb-10 gap-5 justify-center items-center bg-white rounded-3xl">
                <img className="w-32 h-32" src="/icons/failed.svg" alt="failed" />
                <p className="text-[#EE3739] max-sm:text-SubHeading/18/Bold sm:text-SubHeading/18/Bold lg:text-HeadLine/32/ExtraBold mt-4">عملیات شما با خطا مواجه شد!</p>
                {/* <div className="flex flex-row items-center justify-center gap-6 max-sm:w-full sm:w-full lg:w-2/6">
                 <p className="text-gray-500 text-BodyText/12/Regular">تا انتقال به سایت پذیرنده</p>
                 <p className="text-gray-800 text-BodyText/12/Bold">00:05</p>
                </div> */}
                <button onClick={() => navigate('/user/'+ store_id)} className="bg-[#EE3739] text-white text-BodyText/14/Bold rounded-md px-5 py-1 cursor-pointer">
                 بازگشت به صفحه اصلی سایت 
                </button>
               </div>
            )}

            {/* <div className=" flex flex-col max-sm:w-11/12 sm:w-11/12 lg:w-10/12 pt-16 pb-10 gap-10 justify-center items-center bg-white rounded-3xl">
             <div className="felx flex-row px-20 justify-start items-start border-b-2 border-b-green-600">
              <p className="text-[#13B501] text-Title/16/bold">اطلاعات پراخت</p>
             </div>
             <div className="flex flex-col w-full justify-center items-center gap-2">
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">شماره مرجع :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">12345678</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">شماره پیگیری:</p>
                <p className="text-gray-800 text-BodyText/14/Bold">12345678</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">کد ترمینال :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">12345678</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">کد پذیرنده :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">12345678</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">شماره فاکتور :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">12</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">نوع تراکنش :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">خرید</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">مبلغ :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">120,000</p>
                <p className="text-gray-800 text-BodyText/14/Bold">ریال</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">شماره کارت :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">6219 **** **** 4102</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <p className="text-gray-700 text-BodyText/14/Medium">نام بانک :</p>
                <p className="text-gray-800 text-BodyText/14/Bold">سامان</p>
              </div>
             </div>
            </div> */}


</>

        
      </>)}
      </div>
    </motion.div>
  );
};

export default PaymentReport;
