

import { useMutation } from "@tanstack/react-query";
import { STORE_SETTINGS } from "../../api/apiClient";
import { axiosPrivate } from "../../api/axios";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import './css/storeSettingCss.css'
import SetTimePopup from "./popups/SetTimePopup";
import GridLoader from "react-spinners/GridLoader";
const StoreSettings = () => {


  const spring = {
    type: "spring",
    stiffness: 800,
    damping: 30
  };

  const [settingsData, setSettingsData] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [timePopup, setTimePopup] = useState<boolean>(false);
  const [unactiveDays, setUnactiveDays] = useState<any>([]);
  const [payBefore, setPayBefore] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [popupType, setPopupType] = useState<string>('morning')
  const [morningStartClock, setMorningStartClock] = useState<string>('10:00');
  const [morningEndClock, setMorningEndClock] = useState<string>('11:30');
  const [noonStartClock, setNoonStartClock] = useState<string>('13:00');
  const [noonEndClock, setNoonEndClock] = useState<string>('14:00');
  const [nightStartClock, setNightStartClock] = useState<string>('00:00');
  const [nightEndClock, setNightEndClock] = useState<string>('22:00');
  const [morningClock, setMorningClock] = useState<boolean>(true);
  const [noonClock, setNoonClock] = useState<boolean>(true);
  const [nightClock, setNightClock] = useState<boolean>(true);

  const getSettings = () =>
    axiosPrivate
      .get(
        STORE_SETTINGS(),
        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.customer_settings);

  const settingsMutate = useMutation({
    mutationFn: getSettings,
    onSuccess(data, variables, context) {
      setSettingsData(data);
      setPayBefore(data?.user_pay_before);
      setUnactiveDays(data?.unactive_days);
      setMorningStartClock(data?.morning_free_times.split(',')[0])
      setMorningEndClock(data?.morning_free_times.split(',')[1])
      setNoonStartClock(data?.noon_free_times.split(',')[0])
      setNoonEndClock(data?.noon_free_times.split(',')[1])
      setNightStartClock(data?.night_free_times.split(',')[0])
      setNightEndClock(data?.night_free_times.split(',')[1])
      setMorningClock(data?.is_morning_active)
      setNoonClock(data?.is_noon_active)
      setNightClock(data?.is_night_active)
      setLoading(false);
    },
    onMutate() {
      setLoading(true);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    settingsMutate.mutate();
  }, [])

  const DayCart = ({ index, label }: any) => {

    const spr = {
      type: "spring",
      stiffness: 200,
      damping: 30
    };

    function dayHandler(index: number) {
      var sample = unactiveDays
      if (sample.includes(index)) {
        sample.pop(index)
      } else {
        sample.push(index)
      }
      setUnactiveDays([...sample])
    }

    return (
      <div
        onClick={() => dayHandler(index)} className={`flex cursor-pointer gap-2 justify-center items-center p-2 rounded-lg ${unactiveDays.includes(index) ? `bg-red-50` : `bg-green-50`}`}>
        <p className={`${unactiveDays.includes(index) ? `text-red-700` : `text-green-700`} text-Body-OneLine/16/Regular`}>{label}</p>
        <div className={`switch ${unactiveDays.includes(index) ? `bg-red-400` : `bg-green-400`}`} data-isOn={!unactiveDays.includes(index)} >
          <motion.div className="handle bg-primary-50" layout transition={spr} />
        </div>
      </div>
    )
  }

  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i: any) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };


  const settingHandler = async (action?: any) => {
    try {
      const response = await axiosPrivate.put(
        STORE_SETTINGS(undefined), JSON.stringify({ user_pay_before: action === 'payBefore' ? !payBefore : payBefore, is_morning_active: action === 'morning' ? !morningClock : morningClock, is_noon_active: action === 'noon' ? !noonClock : noonClock, is_night_active: action === 'night' ? !nightClock : nightClock, morning_free_times: (morningStartClock + ',' + morningEndClock), noon_free_times: (noonStartClock + ',' + noonEndClock), night_free_times: (nightStartClock + ',' + nightEndClock) }),
        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      if (response?.status >= 200 && response?.status < 300) {
        if (action === 'payBefore')
          setPayBefore(!payBefore);
        else if (action === 'morning')
          setMorningClock(!morningClock);
        else if (action === 'noon')
          setNoonClock(!noonClock);
        else if (action === 'night')
          setNightClock(!nightClock);
      } else {
        console.error(
          "ERROR in supervisor:",
          response.data || response.statusText
        );
      }
    } catch (error) {
    }
  }

  const Clock = ({ startClock, endClock, state }: any) => {

    const [clockStart, setClockStart] = useState<any>(undefined);
    const [clockDistanse, setClockDistance] = useState<any>(undefined);
    function timeSuber() {
      var cs = 0
      var cd = 0
      var startList = startClock.split(':')
      var endList = endClock.split(':')
      var ResMin = Number(endList[1]) - Number(startList[1])
      var ResHour = Number(endList[0]) - Number(startList[0])

      var StartResMin = Number(startList[1])
      var StartResHour = Number(startList[0]) - 12

      if (Math.abs(StartResMin) == 30)
        cs += 1
      cs = cs + (StartResHour / (0.5))
      setClockStart((cs * 15) - 90)

      if (Math.abs(ResMin) == 30)
        cd += 1
      cd = cd + (ResHour / (0.5))
      setClockDistance(cd * (1 / 24))

    }
    useEffect(() => {
      timeSuber();
    }, [])


    return (
      <>
        <motion.circle
          initial={{ pathLength: 0 }}
          animate={{ pathLength: clockDistanse }}
          transition={{
            duration: 2,
            // repeat: Infinity,
            // repeatType: "loop",
          }}
          cx="50"
          cy="50"
          r="45"
          stroke="#0042FE"
          strokeWidth="5"
          fill="none"
          transform={`rotate(${clockStart} 50 50)`}
          strokeLinecap="round"
          // strokeDasharray={2 * Math.PI * 50}
          strokeLinejoin="round"
          className=" flex absolute z-10"
        />
      </>
    )
  }

  function onClose() {
    const body = document.querySelector("body");
    if (body) {
      body.classList.toggle("overflow-hidden");
    }
    setTimePopup(false);
  }


  return (
    <div className="flex flex-col gap-6 justify-start items-start w-full">
      <div className="flex flex-row gap-1 justify-center items-center">
        <div onClick={() => settingHandler('payBefore')} className={`switch cursor-pointer ${payBefore ? `bg-primary-400` : `bg-red-400`}`} data-isOn={payBefore}
        >
          <motion.div className="handle bg-primary-50" layout transition={spring} />
        </div>
        <p className="text-gray-800 text-BodyText/14/Medium">دریافت بیعانه از مشتری</p>
      </div>
      {/* <div className="flex max-sm:flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row w-full gap-6 items-start justify-start">
              <div className="flex gap-6">
                <DayCart index={1} label={'شنبه'} />

                <DayCart index={2} label={'یک شنبه'} />

                <DayCart index={3} label={'دو شنبه'} />
              </div>

              <div className="flex gap-6">
                <DayCart index={4} label={'سه شنبه'} />

                <DayCart index={5} label={'چهار شنبه'} />

                <DayCart index={6} label={'پنج شنبه'} />
              </div>

              <div className="flex gap-6">
                <DayCart index={7} label={'جمعه'} />
              </div>
            </div> */}

      {/* <div className="flex w-full h-[200px] justify-start items-center">
                <div className="flex w-[400px] justify-center items-center">
                      <motion.div
                        layout
                        data-isOpen={isOpen}
                        initial={{ borderRadius: 50 }}
                        className="parent"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <motion.div layout className="child" />
                      </motion.div>
                </div>
            </div> */}


        <div className="flex w-full flex-col justify-start items-start gap-2">
          <p className="text-gray-900 text-BodyText/16/Medium">تنطیمات ساعات استراحت</p>
            <div className="flex max-sm:flex-col max-sm:justify-center max-sm:gap-6 w-full justify-around items-center border border-gray-50 p-2 rounded-lg">
            {settingsMutate.isPending ? (
                        <GridLoader
                        color={'#0042FE'}
                        loading={true}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className="my-32"
                    />
              ) : (<>
            <div  className="flex bg-gray-50 flex-col gap-2 w-[120px] border border-gray-300 justify-center items-center p-2 rounded-2xl">      
              <div onClick={() => {setPopupType('morning');setTimePopup(true)}} className={`flex justify-center items-center relative ${morningClock ? `bg-green-200` : `bg-red-200`} rounded-[50%]`}>

                <img  className=" w-[100px] absolute z-20" src="/icons/clock1.svg" alt="not found" />
                <motion.svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  initial="hidden"
                  animate="visible"
                  className="flex relative z-0"
                >
                          

                    <Clock startClock={morningStartClock} endClock={morningEndClock} />

                </motion.svg>
              </div>

              <div onClick={() => settingHandler('morning')} className={`flex gap-2 p-1 border justify-center items-center ${morningClock ? `border-primary-300 bg-primary-50` : `border-red-300 bg-red-50`}  rounded-md`}>
              <div className={`switch cursor-pointer ${morningClock ? `bg-primary-400` : `bg-red-400`}`} data-isOn={morningClock}
                >
                  <motion.div className="handle bg-primary-50" layout transition={spring} />
                </div>
              <p className={`${morningClock ? `text-primary-700` : `text-red-700`} text-BodyText/14/Bold`}>صبح</p>
              </div>
            </div>

            <div  className="flex bg-gray-50 flex-col gap-2 w-[120px] border border-gray-300 justify-center items-center p-2 rounded-2xl">      
              <div onClick={() => {setPopupType('noon');setTimePopup(true)}} className={`flex justify-center items-center relative ${noonClock ? `bg-green-200` : `bg-red-200`} rounded-[50%]`}>

                <img  className=" w-[100px] absolute z-20" src="/icons/clock1.svg" alt="not found" />
                <motion.svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  initial="hidden"
                  animate="visible"
                  className="flex relative z-0"
                >
                          

                    <Clock startClock={noonStartClock} endClock={noonEndClock} />

                </motion.svg>
              </div>

              <div onClick={() => settingHandler('noon')} className={`flex gap-2 p-1 border justify-center items-center ${noonClock ? `border-primary-300 bg-primary-50` : `border-red-300 bg-red-50`}  rounded-md`}>
              <div className={`switch cursor-pointer ${noonClock ? `bg-primary-400` : `bg-red-400`}`} data-isOn={noonClock}
                >
                  <motion.div className="handle bg-primary-50" layout transition={spring} />
                </div>
              <p className={`${noonClock ? `text-primary-700` : `text-red-700`} text-BodyText/14/Bold`}>ظهر</p>
              </div>
            </div>

            <div  className="flex bg-gray-50 flex-col gap-2 w-[120px] border border-gray-300 justify-center items-center p-2 rounded-2xl">      
              <div onClick={() => {setPopupType('night');setTimePopup(true)}} className={`flex justify-center items-center relative ${nightClock ? `bg-green-200` : `bg-red-200`} rounded-[50%]`}>

                <img  className=" w-[100px] absolute z-20" src="/icons/clock1.svg" alt="not found" />
                <motion.svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  initial="hidden"
                  animate="visible"
                  className="flex relative z-0"
                >
                          

                    <Clock startClock={nightStartClock} endClock={nightEndClock} />

                </motion.svg>
              </div>

              <div onClick={() => settingHandler('night')} className={`flex gap-2 p-1 border justify-center items-center ${nightClock ? `border-primary-300 bg-primary-50` : `border-red-300 bg-red-50`}  rounded-md`}>
              <div className={`switch cursor-pointer ${nightClock ? `bg-primary-400` : `bg-red-400`}`} data-isOn={nightClock}
                >
                  <motion.div className="handle bg-primary-50" layout transition={spring} />
                </div>
              <p className={`${nightClock ? `text-primary-700` : `text-red-700`} text-BodyText/14/Bold`}>شب</p>
              </div>
            </div>
            </>)}

          </div>
        </div>


      {timePopup ? (<>
        <SetTimePopup settingHandler={settingHandler} startClock={popupType === 'morning' ? morningStartClock : (popupType === 'noon' ? noonStartClock : nightStartClock)} endClock={popupType === 'morning' ? morningEndClock : (popupType === 'noon' ? noonEndClock : nightEndClock)} onClose={onClose} type={popupType} 
        setStartClock={popupType === 'morning' ? setMorningStartClock : (popupType === 'noon' ? setNoonStartClock : setNightStartClock)} 
        setEndClock={popupType === 'morning' ? setMorningEndClock : (popupType === 'noon' ? setNoonEndClock : setNightEndClock)} />
      </>) : null} 


    </div>


  )
}

export default StoreSettings;

