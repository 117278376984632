import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
    modalHeightVariants,
    modalVariants,
} from "../../../animation/RoutesAnimations";
import { useState } from "react";
import { axiosPrivate } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { ORDER_INITIAL_APPROVAL, PAYMENT } from "../../../api/apiClient";
import ButtonComponent from "../../../components/elements/ButtonComponent";
import AnimatedBtn from "../../common/AnimatedBtn";
import InputComponent from "../../elements/InputComponent";
import customZ from "../../../utils/setZodCustomMessages";
import { priceSchema } from "../../../utils/customSchemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

interface PriceSchemas {
    amount: number;
  }

const PaymentPopup = ({
    onClose,
    selectRef,
}: any) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const priceSchemaZ = customZ.object({
        amount: priceSchema,
      });

      const {
        handleSubmit,
        control,
        getValues,
        formState: { errors, isValid },
      } = useForm<PriceSchemas>({
        resolver: (data, context, options) => {
          return zodResolver(priceSchemaZ)(data, context, options);
        },
      });


      async function SendPayment (data: any) {
        setLoading(true);
      
        try {

          const formData = {action: 'CUSTOMER_WALLET', description: 'CUSTOMER', amount: Math.round(Number(data?.amount)) * 10}
          const response = await axiosPrivate.post(
            PAYMENT(undefined),
            formData,
                  {withCredentials: true,}
          );
          if (response?.status >= 200 && response?.status < 300) {
            window.location.href = response?.data?.payment_url;
          }
          setLoading(false);
        } catch (error) {
          if ((error as any)?.response) {
            const responseStatus: any = (error as any).response?.status;
            if (responseStatus === 500) {
                console.log('server 500 error')
            }
          } else {
            console.log('server error')
          }
        } finally {
          setLoading(false)
        }
      }

      
    return ReactDOM.createPortal(
        <>
            <motion.div
                variants={modalVariants}
                initial="initial"
                animate="final"
                id="static-modal"
                data-modal-backdrop="static"
                tabIndex={-1}
                aria-hidden="true"
                className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-gray-500 bg-opacity-50"
            >
                <motion.div
                    layout

                    variants={modalHeightVariants}
                    initial="initial"
                    animate="final"
                    className={`relative w-[400px] overflow-hidden bg-white rounded-lg shadow pb-4 px-4 `}
                >
                    <motion.div
                        layout={"position"}
                        initial="initial"
                        animate="final"
                        className="relative"
                    >
                        <div ref={selectRef} className="flex flex-col w-full">
                            <div className=" flex items-end justify-end rounded-t pt-2">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                                >
                                    <img src="/icons/x.svg" alt="icon" width={20} />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            {/* code here */}
                            <div className="flex w-full flex-col gap-6 items-center justify-center p-2">
                                    <InputComponent 
                                        name='amount'
                                        label="مبلغ"
                                        type="text"
                                        isRequired
                                        errors={errors?.amount}
                                        control={control}
                                        maxLength={23}
                                        hasLeftIcon
                                        leftIcon={<><img src="/icons/tooman.svg" alt=" not found" /></>}
                                    />
                                    <AnimatedBtn onClick={handleSubmit(SendPayment)} />
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </>,
        document.getElementById("modal-root")!
    );
};

export default PaymentPopup;
