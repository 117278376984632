import { sellerHeader } from "../../animation/RoutesAnimations";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useState, useEffect } from 'react'
import React from "react";
import { Tab, Tabs } from "../../components/user-dashboard-tabs/Tabs";
import useAddComma from "../hooks/useAddComma";
import InputComponent from "../elements/InputComponent";
import ButtonComponent from "../elements/ButtonComponent";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../api/axios";
import { NEW_USER } from "../../api/apiClient";
import { toast } from "react-toastify";


interface FormValues {
    
    password: string;
    confirmPassword: string;
  }

const NewPassword = ({reset, setSignupMode, setPassMode, getSignupData}: any) => {



  const [loading, setLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [passwordConfShow, setPasswordConfShow] = useState(false);
  const [moreThan8, setMoreThan8] = useState("");
  const [oneNumberOrMore, setOneNumberOrMore] = useState("");
  const [oneOrMoreSpechialChar, setOneOrMoreSpechelChar] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const passwordStepperForm = useForm<FormValues>({
    mode: "onChange",
  });

  const {
    watch,
    handleSubmit,
    formState: { dirtyFields },
  } = passwordStepperForm;

  const watchedPassword = watch("password");

  const getPasswordClass = () => {
    const base = `flex pt-3 pb-3 pr-4 pl-4 content-end items-center gap-2.5 self-stretch rounded-lg border border-solid border-gray-300 bg-gray-50`;

    if (passwordStepperForm.formState.errors.password !== undefined) {
      return `border-red-500 bg-red-50 ${base}`;
    } else if (
      passwordStepperForm.formState.errors.password === undefined &&
      dirtyFields.password === true
    ) {
      return `border-green-500 bg-green-50 ${base}`;
    } else {
      return base;
    }
  };

  const getPasswordTextClass = () => {
    const base = ` outline-none focus:border-gray-50 bg-gray-50 w-full text-Body-OneLine/14/Regular`;
    if (passwordStepperForm.formState.errors.password !== undefined) {
      return `text-red-700 bg-red-50 focus:border-red-50 ${base}`;
    } else if (
      passwordStepperForm.formState.errors.password === undefined &&
      dirtyFields.password === true
    ) {
      return `text-green-500 bg-green-50 focus:border-green-50 ${base}`;
    } else {
      return base;
    }
  };

  const getConfirmPasswordClass = () => {
    const base = `flex pt-3 pb-3 pr-4 pl-4 content-end items-center gap-2.5 self-stretch rounded-lg border border-solid border-gray-300 bg-gray-50`;

    if (passwordStepperForm.formState.errors.confirmPassword !== undefined) {
      return `border-red-500 bg-red-50 ${base}`;
    } else if (
      passwordStepperForm.formState.errors.confirmPassword === undefined &&
      dirtyFields.confirmPassword === true
    ) {
      return `border-green-500 bg-green-50 ${base}`;
    } else {
      return base;
    }
  };

  const getConfirmPasswordTextClass = () => {
    const base = ` outline-none focus:border-gray-50 bg-gray-50 w-full text-Body-OneLine/14/Regular`;
    if (passwordStepperForm.formState.errors.confirmPassword !== undefined) {
      return `text-red-700 bg-red-50 focus:border-red-50 ${base}`;
    } else if (
      passwordStepperForm.formState.errors.confirmPassword === undefined &&
      dirtyFields.confirmPassword === true
    ) {
      return `text-green-500 bg-green-50 focus:border-green-50 ${base}`;
    } else {
      return base;
    }
  };


  useEffect(() => {
    watch((value: any) => {
      // const justEnglish = /^[a-zA-Z0-9!@#$%]+$/.test(value.password);
      const validMoreThan8 = /^.{8,}$/.test(value.password);
      // const validUpperAndLower = /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(
      //   value.password
      // );
      const validOneNumberOrMore = /^(?=.*[0-9]).+$/.test(value.password);
      const validOneOrMoreSpecialChar =
        /^(?=.*[!@#$%]).+$/.test(
          value.password
        );

      setMoreThan8(validMoreThan8 ? " text-green-500" : " text-red-500");
      // setUpperAndLower(
      //   validUpperAndLower ? " text-green-500" : " text-red-500"
      // );
      setOneNumberOrMore(
        validOneNumberOrMore ? " text-green-500" : " text-red-500"
      );
      setOneOrMoreSpechelChar(
        validOneOrMoreSpecialChar ? " text-green-500" : " text-red-500"
      );

      setIsPasswordValid(
        validMoreThan8 &&
          // validUpperAndLower &&
          validOneNumberOrMore &&
          validOneOrMoreSpecialChar
      );
    });
  }, [watchedPassword]);

  async function handleSubmitForm (data: any) {
    setLoading(true);
    const dataform = {
      "user_type" : "CUSTOMER",
      "first_name" : getSignupData('first_name'),
      "last_name" : getSignupData('last_name'),
      "phone_number" : getSignupData('phone_number'),
      "password" : data.password
    }
    try {

      const response = await axiosPrivate.post(
        NEW_USER, dataform,     {withCredentials: true,}
      );
      if (response?.status >= 200 && response?.status < 300) {
        toast.success('ثبت نام موفق')
        setPassMode(false);
        setSignupMode(false);
        reset();
      } else{
        console.log('error')
      }
      console.log(response);
      setLoading(false);
    } catch (error) {
      toast.error('کاربر با این شماره در سیستم موجود می‌باشد')
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className=""
    >
            <form onSubmit={handleSubmit(handleSubmitForm)} className="flex flex-col  items-center justify-center">
        <div className="flex w-full pt-1 pl-8 pr-8 pb-8 flex-col items-center gap-4 self-stretch  ">
          <p
            className="blo
        ck w-36 text-center text-gray-900 text-Title/20/Bold "
          >
            انتخاب رمز عبور
          </p>

          <div className="flex w-full p-0 flex-col items-end gap-2 shrink-0">
            <label
              className=" w-full shrink-0 self-stretch text-gray-900 text-right text-Body-OneLine/14/Regular"
              htmlFor="password"
            >
              رمز عبور
            </label>
            <div className={getPasswordClass()}>
              <div className=" flex p-0 items-center gap-2.5 flex-1">
              <img
                  onClick={() => setPasswordShow(!passwordShow)}
                  src={`${
                    passwordShow
                      ? `/icons/eye-outline.svg`
                      : `/icons/eye-slash-outline.svg`
                  }`}
                  alt="not found"
                />
                <input
                  style={{direction: "ltr"}}
                  {...passwordStepperForm.register("password", {
                    required: "password needed",
                    validate: {
                      justEnglish: (fieldVAlue) => {
                        return (
                          /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+$/.test(
                            fieldVAlue
                          ) || "just english keyboard is valid"
                        );
                      },
                      moreThan8: (fieldValue) => {
                        return (
                          /^.{8,}$/.test(fieldValue) ||
                          "need 8 or more than 8 character"
                        );
                      },
                      // upperAndLower: (filedValue) => {
                      //   return (
                      //     /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(filedValue) ||
                      //     "uppercase and lowercase character needed"
                      //   );
                      // },
                      oneNumberOrMore: (fieldValue) => {
                        return (
                          /^(?=.*[0-9]).+$/.test(fieldValue) ||
                          "one or more number needed"
                        );
                      },
                      oneOrMoreSpechialChar: (fieldValue) => {
                        return (
                          /^(?=.*[!@#$%]).+$/.test(
                            fieldValue
                          ) || "one or more spechial character needed"
                        );
                      },
                    },
                  })}
                  className={getPasswordTextClass()}
                  id="password"
                  type={`${passwordShow ? `text` : `password`}`}
                />

                <img src="./icons/lock-outline.svg" alt="" />
              </div>
            </div>
          </div>

          <div className="flex w-full p-0 flex-col items-end gap-2 shrink-0">
            <label
              className=" w-full shrink-0 self-stretch text-gray-900 text-right text-Body-OneLine/14/Regular"
              htmlFor="password"
            >
              تکرار رمز عبور
            </label>
            <div className={getConfirmPasswordClass()}>
              <div className=" flex p-0 items-center gap-2.5 flex-1">
              <img
                  onClick={() => setPasswordConfShow(!passwordConfShow)}
                  src={`${
                    passwordConfShow
                      ? `./icons/eye-outline.svg`
                      : `./icons/eye-slash-outline.svg`
                  }`}
                  alt=""
                />
                <input
                   style={{direction: "ltr"}}
                  id="confirmpassword"
                  {...passwordStepperForm.register("confirmPassword", {
                    validate: {
                      isSame: (fieldValue) => {
                        return (
                          fieldValue ===
                            passwordStepperForm.watch("password") ||
                          "تکرار رمز عبور یکسان نیست"
                        );
                      },
                    },
                  })}
                  className={getConfirmPasswordTextClass()}
                  type={`${passwordConfShow ? `text` : `password`}`}
                />
                <img src="./icons/lock-outline.svg" alt="" />

              </div>
            </div>
            <div className="flex w-full content-start items-start gap-2.5 flex-col">
              <p
                className={`${moreThan8} text-gray-600 ${
                  passwordStepperForm.formState.errors.confirmPassword ===
                  undefined
                    ? `mt-0`
                    : `mt-0`
                } text-right text-HelpText/11/Regular`}
              >
                حداقل ۸ کاراکتر انتخاب کنید
              </p>
              <p
                className={`${oneNumberOrMore} text-gray-600 text-right text-HelpText/11/Regular`}
              >
                 حداقل یک عدد وارد کنید
              </p>
              <p
                className={`${oneOrMoreSpechialChar} text-gray-600 flex h-4 flex-col content-center shrink-0 text-right text-HelpText/11/Regular`}
              >
                حداقل یک کاراکتر خاص مثل % $ # @ !
              </p>
              <div
                className={`${
                  (watch("password") !== watch("confirmPassword") && watch("confirmPassword") !== '')
                    ? `visible`
                    : `invisible`
                } `}
              >
                <p className=" text-right text-red-500 text-Body-OneLine/14/Regular ">
                تکرار رمز یکسان نیست
                </p>
              </div>
            </div>
          </div>

          <ButtonComponent
            // disabled={isButtonDisabled}
            isClicked={loading}
            type="submit"
            variant="filled"
            className="w-full h-[40px]"
          >
            تایید
          </ButtonComponent>
            <ButtonComponent
                // isClicked={isButtonDisabled}
                onClick={() => setPassMode(false)}
                type="button"
                variant="outlined"
                className="w-full h-[40px]"
            >
                  بازگشت
            </ButtonComponent>

        </div>
      </form>

    </motion.div>
  );
};

export default NewPassword;
