import { useEffect, useState } from "react";
import { axiosPrivate } from "./api/axios";
import { ACCEPT_REJECT, TIME_REQUEST } from "./api/apiClient";
import { useMutation } from "@tanstack/react-query";
import animationData2 from "../src/jsons/new-user.json";
import Lottie from "react-lottie";
import ButtonComponent from "./components/elements/ButtonComponent";
import { convertDateTimeToJalali } from "./utils/useDateTimeConvertToJalali";

export const UserNotification = ({setTimeTableRefresh, timeTableRefresh}: any) => {

    const [userNotification, setUserNotification] = useState<boolean>(false);
    const [acceptRejectLoading, setAcceptRejectLoading] = useState<boolean>(false);
    const [loadingTimeRequest, setLoadingTimeRequest] = useState<boolean>(false);
    const [requestTimesData, setRequestTimesData] = useState<any>(undefined);
    const [newu, setNewU] = useState(false);

    const defaultOptions2 = {
        loop: false,
        autoplay: false,
        animationData: animationData2,
        renderer: "svg",
      };
    
    const getRequestTimes = () =>
        axiosPrivate
          .get(
            TIME_REQUEST(),
    
            {
              headers: {
                // prettier-ignore
                "accept": "application/json",
                // prettier-ignore
                // "Authorization": `Bearer ${access_token}`,
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true,
            }
          )
          .then((res) => res.data.requested_times);
    
          const timeRequestMutate = useMutation({
            mutationFn: getRequestTimes,
            onSuccess(data, variables, context) {
                setRequestTimesData(data);
                setLoadingTimeRequest(false);
                setTimeout(function(){
                  timeRequestMutate.mutate();
               }, 20000);
           
            },
            onMutate() {
              setLoadingTimeRequest(true);
            },
            onError() {
              setLoadingTimeRequest(false);
            },
          });
    
          useEffect(() => {
            timeRequestMutate.mutate();
          }, [])  
          
    async function AcceptReject (data: any, ACCREJ: string) {
        setAcceptRejectLoading(true);
    
        try {
            const response = await axiosPrivate.post(
            ACCEPT_REJECT, {status: ACCREJ, time: data.time, time_unit: data.time_unit, date: data.date},     {withCredentials: true,}
          );
          if (response?.status >= 200 && response?.status < 300) {
            timeRequestMutate.mutate();
            setTimeTableRefresh(!timeTableRefresh);
          }
          setAcceptRejectLoading(false);
        } catch (error) {
          if ((error as any)?.response) {
            const responseStatus: any = (error as any).response?.status;
            if (responseStatus === 500) {
                console.log('server 500 error')
            }
          } else {
            console.log('server error')
          }
        } finally {
          setAcceptRejectLoading(false)
        }
      }

      function timeCalculator(time: any, time_unit: any) {
        var min_unit1 = time * (time_unit - 1)
        var hours1 = Math.floor(min_unit1 / 60)
        var minutes1 = min_unit1 % 60
        hours1 = hours1 + 6
        var min_unit2 = time * (time_unit)
        var hours2 = Math.floor(min_unit2 / 60)
        var minutes2 = min_unit2 % 60
        hours2 = hours2 + 6
        return `${hours1}:${minutes1} تا ${hours2}:${minutes2}`
      }
      
    return (
        <div className={`flex flex-col-reverse items-start ${userNotification ? `w-[260px]` : `w-[60px]`}   bottom-2 right-2 fixed z-20 overflow-y-clip ${userNotification ? `min-h-44` : ``}`}>
        <div onClick={() => setUserNotification(!userNotification)} className="flex z-20 m-2 cursor-pointer">
        <Lottie options={defaultOptions2} height={50} width={50} isStopped={newu}/>
        </div>
        {requestTimesData?.length > 0 ? (<span onClick={() => setUserNotification(!userNotification)} className="flex h-3 w-3 m-3 z-30 cursor-pointer absolute bottom-0 right-0">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-400"></span>
        </span>) : null}
        <div className={`${userNotification ? `flex` : `hidden`} ${userNotification ? `w-[250px]` : `w-[0px]`} flex-col-reverse absolute z-10 right-2 border border-primary-300 bottom-1 min-h-40 max-h-128 bg-white rounded-lg p-2 overflow-y-scroll`}>
          <div className="flex flex-col gap-4 mb-14 ">
          
            {requestTimesData?.length > 0 && requestTimesData?.map((item: any, index: number) => (
              <div className="flex flex-col w-full border border-yellow-300 bg-yellow-50 rounded-md p-2 gap-4">
                <p className="text-gray-400 text-BodyText/14/Medium">
                مشتری <span className="text-gray-700 text-BodyText/14/Bold">{item.user_fullname}</span> درخواست رزرو نوبت برای <span className="text-gray-700 text-BodyText/14/Bold">{item.name}</span> را در زمان <span className="text-gray-700 text-BodyText/14/Bold">{timeCalculator(item.time, item.time_unit)}</span> و در تاریخ <span className="text-gray-700 text-BodyText/14/Bold">{convertDateTimeToJalali(item.date)}</span> را دارد.
                </p>
                <div className="flex gap-4 justify-center items-center">
                  <ButtonComponent
                  onClick={() => AcceptReject(item, "REJECT")}
                  className="bg-red-500"
                  >رد</ButtonComponent>
                  <ButtonComponent
                  onClick={() => AcceptReject(item, "ACCEPT")}
                  >رزرو</ButtonComponent>
                </div>
              </div>
            ))}
            {requestTimesData?.length === 0 ? (<div className="flex flex-col justify-center items-center">
            <p className="text-gray-400 text-BodyText/12/Medium">هیچ درخواست ثبت نوبتی وجود ندارد</p>
            </div>) : null}
          </div>
        </div>
</div>
    )
}

export default UserNotification;
