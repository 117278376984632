import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
  modalHeightVariants,
  modalVariants,
} from "../../../animation/RoutesAnimations";
import { useState } from "react";
import { axiosPrivate } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { PAYMENT, TIME_REQUEST } from "../../../api/apiClient";
import ButtonComponent from "../../../components/elements/ButtonComponent";
import InputComponent from "../../elements/InputComponent";
import customZ from "../../../utils/setZodCustomMessages";
import { notEmptyAndPersianField, phonenumberSchema } from "../../../utils/customSchemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { convertDateTimeToJalali } from "../../../utils/useDateTimeConvertToJalali";

interface TimeRequestSchemas {
  user_fullname: string;
  user_phone_number: number;
}


const  RequestPopup = ({
  onClose,
  selectRef,
  mutation,
  store_id,
  time,
  time_unit,
  name,
  customer,
  date_number,
  user_pay_before,
  inputDate,
  store_name,
  price,
  walletMutate
}: any) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [percent, setPercent] = useState<any>(0.2);

  function addCama(value: any) {
    var valueString = String(Math.round(value));
    var reversed = valueString.split("").reverse();
    for (let i = 3; i < reversed.length; i += 4) {
        reversed.splice(i, 0, ",");
    }
    return reversed.reverse().join("");
}

const timeRequestSchema = customZ.object({
  user_fullname: notEmptyAndPersianField,
  user_phone_number: phonenumberSchema,
});


const {
  handleSubmit,
  control,
  getValues,
  formState: { errors, isValid },
} = useForm<TimeRequestSchemas>({
  resolver: (data, context, options) => {
    return zodResolver(timeRequestSchema)(data, context, options);
  },
});


async function SendTimeRequest (data: any) {
  setLoading(true);

  try {
    const formDataForPay = {
      "full_name": data?.user_fullname,
      "phone_number": data?.user_phone_number,
      "amount": Math.round(Number(price) * percent ) * 10,
      "description": "USER",
      "action": "USER_TIME_REQUEST",
      time,
      time_unit,
      date_number,
      name,
      ...data
  }
    const formData = {...data, time, time_unit, name, date_number, amount: Math.round(Number(price) * percent ) * 10}
    const response = await axiosPrivate.post(
      customer ? (TIME_REQUEST()) : (user_pay_before ? PAYMENT(store_id) : TIME_REQUEST(store_id, 'INDIRECT')),
      customer ? (formData) : (user_pay_before ? formDataForPay : formData),
            {withCredentials: true,}
    );
    if (response?.status >= 200 && response?.status < 300) {
      if (user_pay_before) { 
          localStorage.setItem("store_id", store_id);
          localStorage.setItem("user_phone_number", data?.user_phone_number);
          localStorage.setItem("user_fullname", data?.user_fullname);
          localStorage.setItem("time", time);
          localStorage.setItem("time_unit", time_unit);
          localStorage.setItem("inputDate", inputDate);
          localStorage.setItem("name", name);
          localStorage.setItem("store_name", store_name);
          window.location.href = response?.data?.payment_url;
      } else
        {
          mutation.mutate();
          if (walletMutate)
            walletMutate.mutate()
          onClose()
        }

    }
    setLoading(false);
  } catch (error) {
    if ((error as any)?.response) {
      const responseStatus: any = (error as any).response?.status;
      if (responseStatus === 500) {
          console.log('server 500 error')
      }
    } else {
      console.log('server error')
    }
  } finally {
    setLoading(false)
  }
}

function timeCalculator(time: any, time_unit: any) {
  var min_unit1 = time * (time_unit - 1)
  var hours1 = Math.floor(min_unit1 / 60)
  var minutes1 = min_unit1 % 60
  hours1 = hours1 + 6
  var min_unit2 = time * (time_unit)
  var hours2 = Math.floor(min_unit2 / 60)
  var minutes2 = min_unit2 % 60
  hours2 = hours2 + 6
  return `${hours1}:${minutes1} تا ${hours2}:${minutes2}`
}

  return ReactDOM.createPortal(
    <>
      <motion.div
        variants={modalVariants}
        initial="initial"
        animate="final"
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-gray-500 bg-opacity-50"
      >
        <motion.div
          layout
          
          variants={modalHeightVariants}
          initial="initial"
          animate="final"
          className={`relative w-[400px] overflow-hidden bg-white rounded-lg shadow pb-4 px-4 `}
        >
          <motion.div
            layout={"position"}
            initial="initial"
            animate="final"
            className="relative"
          >
            <div ref={selectRef} className="flex flex-col w-full">
              <div className=" flex items-end justify-end rounded-t pt-2">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                >
                  <img src="/icons/x.svg" alt="icon" width={20} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* code here */}
              <div className="flex flex-col w-full justify-center items-center">
                <div className="flex justify-center m-4">
                  <img className="w-12 h-12" src="/icons/rezervsho-logo.svg" alt="not found" />
                </div>
                {user_pay_before ? (<>
                  <p className="text-yellow-400 text-BodyText/12/Light p-5 px-8 text-center"> {store_name} برای رزرو نوبت مبلغ <span className="text-BodyText/12/Bold text-yellow-500">{addCama(Math.round(Number(price) * percent ))}</span> هزار تومان پیشپرداخت میگیرد!</p>
                </>) : null}
                <p className="text-gray-400 text-BodyText/14/Medium p-5 px-8">
               رزرو نوبت برای <span className="text-gray-700 text-BodyText/14/Bold">{name}</span> در زمان <span className="text-gray-700 text-BodyText/14/Bold">{timeCalculator(time, time_unit)}</span> و در تاریخ <span className="text-gray-700 text-BodyText/14/Bold">{convertDateTimeToJalali(inputDate)}</span> .
                </p>
                
                <div className="flex justify-center bg-gray-800 h-[1px] w-5/6"></div>
                <form onSubmit={handleSubmit(SendTimeRequest)} className="flex flex-col justify-center items-center p-4">
                  <InputComponent 
                    name='user_fullname'
                    label="نام و نام‌خانوادگی"
                    type="text"
                    errors={errors?.user_fullname}
                    control={control}
                  />

                  <InputComponent 
                      name='user_phone_number'
                      label="شماره تلفن"
                      type="text"
                      errors={errors?.user_phone_number}
                      control={control}
                      maxLength={11}
                  />

                  <div className="flex gap-4">
                    <ButtonComponent 
                    onClick={onClose}
                    variant="outlined"
                    >
                      لغو
                    </ButtonComponent>
                    <ButtonComponent 
                    type="submit"
                    isClicked={loading}
                    >
                      رزرو
                    </ButtonComponent>
                  </div>
                </form>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>,
    document.getElementById("modal-root")!
  );
};

export default RequestPopup;
