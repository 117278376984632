import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
  modalHeightVariants,
  modalVariants,
} from "../../../animation/RoutesAnimations";
import { useEffect, useState } from "react";
import { axiosPrivate } from "../../../api/axios";
import ScrollTimePicker from "../../common/ScrollTimePicker"

const  SetTimePopup = ({
    onClose,
    type,
    settingHandler,
    setStartClock, 
    setEndClock,
    startClock,
    endClock
}: any) => {


    const [loading, setLoading] = useState(false);
    const [startTime, setStartTime] = useState<string>(startClock);
    const [endTime, setEndTime] = useState<string>(endClock);
    const [startError, setStartError] = useState<boolean>(false);
    const [endError, setEndError] = useState<boolean>(false);
    const [evenTimeError, setEvenTimeError] = useState<boolean>(false);
    const [upError, setUpError] = useState<boolean>(false)
//   async function deleteFunction (time: any, time_unit: any, date_number: any) {
//     setLoading(true);

//     try {
//       const response = await axiosPrivate.delete(
//         TIME_TABLE_CUSTOMERS(undefined, undefined, time=time, time_unit=Number(time_unit), date_number=String(date_number)),           {
//             withCredentials: true,
//           }
//       );
//       if (response?.status >= 200 && response?.status < 300) {
//         if (user_pay_before) { 
//             setTimeTableData(response?.data?.table)
//         } else
//           {
//             mutation.mutate();
//             if (walletMutate)
//               walletMutate.mutate()
//             onClose()
//           }
  
//       }
//       setLoading(false);
//     } catch (error) {
//       if ((error as any)?.response) {
//         const responseStatus: any = (error as any).response?.status;
//         if (responseStatus === 500) {
//             console.log('server 500 error')
//         }
//       } else {
//         console.log('server error')
//       }
//     } finally {
//       setLoading(false)
//     }
//   }

function timeValue (time: string) {
  let timeData = time.split(':')
  return Number(timeData[0]) + (Number(timeData[1]) / 60)
}

function minChecker (time: string) {
  let timeData = time.split(':')
  if (timeData[1] === '00' || timeData[1] === '30')
    return true
  else 
    return false
  
}

useEffect(() => {
  if (minChecker(startTime)) {
    if (minChecker(endTime)) {
  
  if (type === 'morning') {
    if (timeValue(startTime) < 6 || timeValue(startTime) > 11.5) {
      setStartError(true)
    }else{
      setStartClock(() => startTime)
      setEndClock(() => endTime)
      setStartError(false)
    }

    if(timeValue(endTime) < 7 || timeValue(endTime) > 12){
      setEndError(true)
    }else{
      setStartClock(() => startTime)
      setEndClock(() => endTime)
      setEndError(false)
    }
  

  } else if (type === 'noon') {
    if (timeValue(startTime) < 12 || timeValue(startTime) > 17.5) {
      setStartError(true)
    }else{
      setStartClock(() => startTime)
      setEndClock(() => endTime)
      setStartError(false)
    }

    if(timeValue(endTime) < 12.5 || timeValue(endTime) > 18){
      setEndError(true)
    }else{
      setStartClock(() => startTime)
      setEndClock(() => endTime)
      setEndError(false)
    }
  

  } else if (type === 'night') {

    if (timeValue(startTime) < 18 || timeValue(startTime) > 22.5) {
      setStartError(true)
    }else{
      setStartClock(() => startTime)
      setEndClock(() => endTime)
      setStartError(false)
    }

    if(timeValue(endTime) < 18.5 || timeValue(endTime) > 23){
      setEndError(true)
    }else{
      setStartClock(() => startTime)
      setEndClock(() => endTime)
      setEndError(false)
    }
  }

  }
  else {
    setEndError(true)
  }
  }
  else {
    setStartError(true)
  }


  if (startTime === endTime) {
    setEvenTimeError(true)
  }else {
    setStartClock(() => startTime)
    setEndClock(() => endTime)
    setEvenTimeError(false)
  }
  let startTimeSplit = startTime.split(':')
  let endTimeSplit = endTime.split(':')
  if (Number(endTimeSplit[0]) < Number(startTimeSplit[0])) {
    setUpError(true)
  } else if (Number(endTimeSplit[0]) === Number(startTimeSplit[0])) {
    if (Number(endTimeSplit[1]) < Number(startTimeSplit[1])){
      setUpError(true)
    } else {
      setStartClock(() => startTime)
      setEndClock(() => endTime)
      setUpError(false)
    }
  } else {
    setStartClock(() => startTime)
    setEndClock(() => endTime)
    setUpError(false)
  }
},[startTime, endTime])

useEffect(() => {
  const body = document.querySelector("body");
  if (body) {
    body.classList.toggle("overflow-hidden");
  }
}, []);

function handelClick () {
  settingHandler()
  onClose()
}
  return (
    <>
          <motion.div
        variants={modalVariants}
        initial="initial"
        animate="final"
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center overflow-hidden items-end h-full bg-gray-500 bg-opacity-50"
      >
        <motion.div
          layout
          
          variants={modalHeightVariants}
          initial="initial"
          animate="final"
          className={`relative w-[400px] overflow-hidden mb-32 bg-white rounded-lg shadow pb-4 px-4 `}
        >
          <motion.div
            layout={"position"}
            initial="initial"
            animate="final"
            className="relative"
          >
            <div className="flex flex-col w-full">
              <div className=" flex items-end justify-end rounded-t pt-2">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                >
                  <img src="/icons/x.svg" alt="icon" width={20} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* code here */}
              <div className="flex flex-col gap-6 w-full justify-center items-center">

                  {evenTimeError || upError ? (upError ? (<p className="text-red-500 text-BodyText/12/Medium flex w-full px-4 text-center">زمان پایان از شروع کمتر است!</p>) : (<p className="text-red-500 text-BodyText/12/Medium flex w-full px-4 text-center">هر دو زمان یکسان هستند!</p>)) 
                  : (<p className="text-yellow-500 text-BodyText/12/Medium flex w-full px-4 text-center">{type === 'morning' ? 'لطفا زمان را با فاصله 30 دقیقه و در 6 صبح تا 12 ظهر وارد کنید.': (type === 'noon' ? 'لطفا زمان را با فاصله 30 دقیقه و در 12 ظهر تا 6 شب وارد کنید.' : 'لطفا زمان را با فاصله 30 دقیقه و در 6 شب تا 11 شب وارد کنید.')}</p>)}
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col justify-center items-start w-5/12">
                    <ScrollTimePicker label={'از'} time={startTime} setTime={setStartTime} error={startError} />
                    </div>
                    <div className="flex w-2/12 justify-center items-center">
                        <div className="flex h-[100px] w-[1px] bg-gray-200">

                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-start w-5/12">
                    <ScrollTimePicker label={'تا'} time={endTime} setTime={setEndTime} error={endError} />
                    </div>
                  </div>
                  <div onClick={() => {if (!upError && !evenTimeError && !endError && !startError) handelClick()}} className={`flex w-full ${upError || evenTimeError || endError || startError ? `hover:bg-red-400 bg-red-500` : `hover:bg-primary-400 bg-primary-500`}  text-white justify-center items-center h-10 rounded-[20px]`}>
                  تایید
                  </div>
                  </div>
                </div>
              </motion.div>
        </motion.div>
      </motion.div>

    </>
  );
};

export default SetTimePopup;
