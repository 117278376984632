import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
  modalHeightVariants,
  modalVariants,
} from "../../../animation/RoutesAnimations";
import animationData from "../../../../src/jsons/clock-yellow.json";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/elements/ButtonComponent";
import React from "react";
import Lottie from "react-lottie";

const  TableDetailPopup = ({
  onClose,
  selectRef,
  setSelectedTime,
  data,
  setDeletePopup,
  setPopup,
  setTimeUnit,
}: any) => {

  const navigate = useNavigate();


  const DetailBody = ({data}: any) => {

    const classes = `${data.status==='ACTIVE' ? (`border-red-600 text-red-500 hover:bg-red-50`) : (data.status === 'NONE') ? (`border-green-200 text-green-800 hover:bg-green-50`) : (data.status === 'PENDING') ? (`border-yellow-200 text-yellow-500 hover:bg-yellow-50`) : (`border-red-600 text-red-500 hover:bg-red-50`)}`
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        renderer: "svg",
      };

    return (
      <React.Fragment>
        <p className="flex w-[160px] px-2.5">
          <ButtonComponent
            onClick={() => {setTimeUnit(data?.time_unit);setSelectedTime(data?.time);if(data?.status === 'NONE') {setPopup(true)} else if (data?.status === 'ACTIVE' || data?.status === 'CONFLICT') {setDeletePopup(true)}}}
            // disabled
            hasIcon
            icon={<>
              {data.status === "ACTIVE" ? (<img
                className=" h-[18px] w-[18px]"
                src="./icons/x.svg"
                alt="not found"
              />) : (data.status === "NONE" ? (<>
              <img
                className=" h-[24px] w-[24px]"
                src="./icons/tik.svg"
                alt="not found"
              />
              </>) : (data.status === "PENDING" ? (<>
                  <div className="flex flex-col w-full justify-center">
                    <Lottie options={defaultOptions} height={18} width={18} />
                  </div>
              </>) : (<img
                className=" h-[18px] w-[18px]"
                src="./icons/x.svg"
                alt="not found"
              />)))}
              </>
            }
            bgColor=""
            className={`${classes}`}
            variant={"outlined"}
          >
            {data.status === "NONE" ? 'ثبت نوبت' : (data.status === "ACTIVE") ? 'حذف نوبت' : (data.status === "PENDING") ? 'تایید نوبت' : 'حذف نوبت'}
          </ButtonComponent>
        </p>
      </React.Fragment>
    );
  };

  return ReactDOM.createPortal(
    <>
      <motion.div
        variants={modalVariants}
        initial="initial"
        animate="final"
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-gray-500 bg-opacity-50"
      >
        <motion.div
          layout
          
          variants={modalHeightVariants}
          initial="initial"
          animate="final"
          className={`relative w-[400px] overflow-hidden bg-white rounded-lg shadow pb-4 px-4 `}
        >
          <motion.div
            layout={"position"}
            initial="initial"
            animate="final"
            className="relative"
          >
            <div ref={selectRef} className="flex flex-col w-full">
              <div className=" flex items-end justify-end rounded-t pt-2">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                >
                  <img src="/icons/x.svg" alt="icon" width={20} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* code here */}
              <div className="flex w-full flex-col justify-center items-center mb-4">
                  <img className="w-[32px]" src="/icons/rezervsho-logo.svg" alt="not found" />
              </div>
              <div className="flex flex-col w-full justify-center items-center px-10 gap-1">
                  <div className="flex justify-between w-full">
                    <p className="text-gray-400 text-BodyText/14/Medium">نام خدمت</p>
                    <p className="text-primary-900 text-BodyText/14/Medium">{data?.name ? data?.name : `--`}</p>
                  </div>
                  <div className="flex justify-between w-full">
                    <p className="text-gray-400 text-BodyText/14/Medium">نام رزرو شده</p>
                    <p className="text-primary-900 text-BodyText/14/Medium">{data?.user_fullname ? data?.user_fullname : `--`}</p>
                  </div>
                  <div className="flex justify-between w-full">
                    <p className="text-gray-400 text-BodyText/14/Medium">شماره رزرو شده</p>
                    <p className="text-primary-900 text-BodyText/14/Medium">{data?.user_phone_number ? data?.user_phone_number : `--`}</p>
                  </div>
              </div>
              <div className="flex w-full justify-center items-center gap-2 mt-6">
                <ButtonComponent
                variant="outlined"
                onClick={onClose}
                >لغو</ButtonComponent>

                  <DetailBody data={data} />
              </div>


            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>,
    document.getElementById("modal-root")!
  );
};

export default TableDetailPopup;
