import { useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import EditProfile from "./popups/EditProfile";

const Profile = ({
  loading,
  profileData,
  mutation,
} : any
) => {

  const [popup, setPopup] = useState<boolean>(false);
  
  function onClose () {
    setPopup(false)
  }

    return (
            <>
            <p className="text-gray-900 text-BodyText/16/Bold py-4">پروفایل</p>
            <div className="flex flex-col justify-center items-center bg-gray-100 max-sm:w-full sm:w-full lg:w-3/4 h-4/5 rounded-4xl p-5 gap-2">
              {loading ? (<>
                <FadeLoader
                      color={'#023E8A'}
                      loading={true}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                  />
              </>) : (
                <>
            
                <div className=" flex w-full max.sm:justify-end sm:justify-end lg:justify-start">
                    <img onClick={() => setPopup(true)} className=" cursor-pointer" src="/icons/edit-pen.svg" alt="not found" />
                </div>
                <div className="flex w-full max-sm:flex-col-reverse sm:flex-col-reverse lg:flex-row items-center justify-between ml-2 mt-2 max-sm:gap-4 sm:gap-4">
                    <p className="text-gray-900 max-sm:text-Title/14/bold sm:text-Title/14/bold lg:text-HeadLine/24/Bold mr-4">نام کسب و کار : {profileData?.name}</p>
                    <img className="w-24 h-24 bg-primary-500 rounded-[50%]" src="/icons/user-white.svg" alt="not found" />
                </div>
                <div className="w-full ">
                    <p className="text-gray-500 max-sm:text-BodyText/14/Regular sm:text-BodyText/14/Regular lg:text-BodyText/16/Medium">نام و نام خانوادگی :  {profileData?.owner}</p>
                </div>
                <div className="w-full">
                    <p className="text-gray-500 max-sm:text-BodyText/14/Regular sm:text-BodyText/14/Regular lg:text-BodyText/16/Medium">شماره تماس : {profileData?.sid}</p>
                </div>
                <div className="w-full">
                    <p className="text-gray-500 max-sm:text-BodyText/14/Regular sm:text-BodyText/14/Regular lg:text-BodyText/16/Medium">آدرس : {profileData?.address}</p>
                </div>
                <div className="w-full">
                    <p className="text-gray-500 max-sm:text-BodyText/14/Regular sm:text-BodyText/14/Regular lg:text-BodyText/16/Medium">نوع خدمت : {profileData?.work_name}</p>
                </div>
                <div className="flex items-start"> 
                {(profileData?.lng === 55.167225523743696 && profileData?.lat === 37.2524570543776) || (profileData?.lng === undefined || profileData?.lat === undefined) ? (<>
                <p className="text-BodyText/12/Medium text-red-700 border border-red-400 bg-red-50 p-2 rounded-xl">موقعیت مکانی ثبت نشده</p>
                </>) : (<>
                  <p className="text-BodyText/12/Medium text-green-700 border border-green-400 bg-green-50 p-2 rounded-xl">موقعیت مکانی ثبت شده</p>
                </>)}
                </div>
                </>
            
              )}
            {popup && <EditProfile onClose={onClose} mutation={mutation} inpLat={profileData?.lat} inpLng={profileData?.lng} />}
            </div>
            </>
    )}

    export default Profile;

