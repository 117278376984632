import { sellerHeader } from "../animation/RoutesAnimations";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from 'react'
import React from "react";
import { Tab, Tabs } from "../components/user-dashboard-tabs/Tabs";
import useAddComma from "../components/hooks/useAddComma";
import InputComponent from "../components/elements/InputComponent";
import { useNavigate } from "react-router-dom";
import animationData from "../../src/jsons/email.json";
import { axiosPrivate } from "../api/axios";
import { LOGOUT } from "../api/apiClient";
import Lottie from "react-lottie";

const Footer = () => {

  const addCama = useAddComma();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  var [loginStatus, setLoginStatus] = useState(false)
  const [newu, setNewU] = useState(false)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };



  return (
    <div className="flex flex-col w-full items-center justify-center bg-white gap-6">
      <img className="w-16 h-16 m-4" src="/icons/rezervsho-logo.svg" alt="not found" />
      <div className="flex max-sm:flex-col max-sm:justify-center max-sm:items-center w-11/12 gap-10 justify-around items-start p-4 border border-primary-500 rounded-xl">
        <div className="flex flex-col max-sm:w-8/12 sm:w-5/12 md:w-3/12 lg:w-3/12 gap-3 max-sm:justify-center max-sm:items-center">

        <a href="https://rezerv-sho.ir" className="text-primary-700 hover:text-primary-500 text-HeadLine/28/Bold mb-4">رزرو شو </a>

          <div className="flex w-full justify-start items-center">
            <div onClick={() => window.location.href = 'mailto:rezervsho@gmail.com'} className="flex flex-col w-[30px] items-center justify-center ml-4 cursor-pointer">
              <Lottie isClickToPauseDisabled={true} options={defaultOptions} height={30} width={30} />
            </div>
            <p onClick={() => window.location.href = 'mailto:rezervsho@gmail.com'} className="text-primary-700 text-Title/16/Regular cursor-pointer hover:text-primary-500">rezervsho@gmail.com</p>
          </div>
          <div className="flex justify-start items-start gap-2">
            <img onClick={() => window.open("https://instagram.com/rezerv_sho/", "_blank")} className="w-8 h-8" src="/icons/instagram-blue.svg" alt="not found" />
            <img onClick={() => window.open("https://t.me/rezerv_sho/", "_blank")} className="w-7 h-7" src="/icons/telegram-blue.svg" alt="not found" />
          </div>
          <a href="tel:09117944638" className="text-primary-700 hover:text-primary-500 cursor-pointer">989117944638+</a>
          <a href="/roles" className="text-primary-700 hover:text-primary-500 text-Title/16/Regular cursor-pointer">قوانین رزرو شو</a>
          <a href="/aboutus" className="text-primary-700 hover:text-primary-500 text-Title/16/Regular cursor-pointer">درباره ما</a>

        </div>
        <div className="flex flex-col max-sm:w-8/12 sm:w-5/12 md:w-3/12 lg:w-3/12 gap-3 max-sm:justify-center max-sm:items-center">
          <p className="text-primary-700 text-HeadLine/28/Bold mb-4">خدمات</p>
          <a href="/login" className="text-primary-700 text-Title/16/Regular cursor-pointer hover:text-primary-500">نوبت دهی آنلاین</a>

          <div className="flex justify-center items-center gap-2">
            <a referrerPolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=514050&Code=u8sYgByO9vIk8kon7bNbDI7wRVm0m7Ip'><img className=" cursor-pointer" referrerPolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=514050&Code=u8sYgByO9vIk8kon7bNbDI7wRVm0m7Ip' alt='' data-code='u8sYgByO9vIk8kon7bNbDI7wRVm0m7Ip' /></a>
            <div className="flex justify-center items-center">
              <img src="/icons/zarinpal.png" alt="not found" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-5/6 border-t border-gray-300 items-center justify-center">
        <p className="text-gray-500 text-BodyText/12/Medium text-center">کلیه حقوق این سایت متعلق به رزرو شو است</p>
      </div>
    </div>
  );
};

export default Footer;
