import MapScreen from "../../components/map/Map";


const TopRight = ({profileData, myRef}: any) => {

  return (

          <div className="flex max-sm:w-full sm:w-full lg:w-[50%] justify-center items-center">
            <div className=" max-sm:hidden sm:hidden lg:flex w-[180px] h-[180px] bg-yellow-300 justify-center items-center rounded-[30%] drop-shadow z-10">
            <img className="w-20 h-20" src="/icons/user-white.svg" alt="not found" />
            </div>
            {/* for mobile */}
            <div className=" max-sm:flex sm:flex lg:hidden flex-col w-11/12 rounded-3xl bg-primary-800 items-center py-6 gap-8">
              <div className="w-full flex justify-center items-center flex-col">
                {/* user profile */}
                <img className="w-20 h-20" src="/icons/user-white.svg" alt="not found" />
                <p className="text-white  text-HeadLine/28/Bold">{profileData?.name}</p>
              </div>
              <div className="flex flex-row items-center w-full gap-8 px-4">
                <p className="text-BodyText/14/Medium text-white mr-4">شغل کاربر :</p>
                <p className="text-BodyText/16/Bold text-white">{profileData?.work_name}</p>

              </div>
              <div className="flex flex-row items-center w-full gap-8 px-4">
                <p className="text-BodyText/14/Bold text-white mr-4">نام کاربر :</p>
                <p className="text-Title/20/Bold text-white">{profileData?.owner}</p>

              </div>
              <div className="flex flex-row items-center w-full gap-8 px-4">
                <p className="text-BodyText/14/Medium text-white mr-4">آدرس :</p>
                <p className=" w-3/5 text-BodyText/16/Bold text-white">{profileData?.address}</p>
              </div>
              <MapScreen lng={profileData?.lng} lat={profileData?.lat} justShow />
              <div className="w-[64px] h-[16px] gap-4 justify-around items-start flex flex-row">
               {/* <img src="/icons/telegram-white.svg" alt="telegram icon" className="w-[16px] h-[16px]"/>
               <img src="/icons/instagram-white.svg" alt="instagram icon" className="w-[16px] h-[16px]"/>
               <img src="/icons/phone-white.svg" alt="phone icon" className="w-[16px] h-[16px]"/> */}
              </div>

              <div className="w-full flex justify-center items-center">
               <button onClick={() => myRef.current.scrollIntoView()} className="bg-[#ffffff] w-[40%]  rounded-[10px] text-primary-800 text-BodyText/16/Bold/Bold p-2">درخواست نوبت</button>
              </div>
            </div>
           
           
            <div className="max-sm:hidden sm:hidden lg:flex flex-col w-full items-start justify-end mt-10">
                <div className="flex w-[200px] h-[44px] rounded-l-[20px] bg-primary-200  justify-start items-center relative left-6">
                  <p className="text-primary-900 text-BodyText/14/Bold mr-8">{profileData?.name}</p>
                </div>
                <div className="flex w-[300px] h-[60px] rounded-l-[20px] bg-primary-300  justify-start items-center relative left-6 bottom-1">
                  <p className=" text-white text-BodyText/14/Regular mr-8">{profileData?.owner}</p>
                </div>
            </div>
            
          </div>
  );

};

export default TopRight;
