

const FILL_REPRESENTATIVE_INFO = "user/fill-representative-info/";

export const WORK_TYPE = (store_id?: string, id?: string) => `work_type/${store_id ? `?store_id=${store_id}` : ``}${id ? `?id=${id}` : ``}`;

export const PROFILE = "profile/";

export const WALLET = "wallet/";

export const PAYMENT_HISTORY = "payment_history/";

export const GET_CUSTOMER_PROFILE = (store_id: string) => `get_customer_profile/?store_id=${store_id}`;

export const ACCEPT_REJECT = "accept_reject/";

export const TIME_REQUEST = (store_id?: string, type?: string) => `time_request/${store_id ? `?store_id=${store_id}` : ``}${type ? `&type=${type}` : ``}`;

export const PAYMENT = (store_id?: string, authority?: any) => `payment/${store_id ? `?store_id=${store_id}` : ``}${authority ? `?authority=${authority}` : ``}`;

export const STORE_SETTINGS = (store_id?: string) => `store_settings/${store_id ? `?store_id=${store_id}` : ``}`;

export const TIME_TABLE_CUSTOMERS = (date?: any, store_id?: any, time?: any, time_unit?: any, date_number?: any) =>
   `time_table_customer/?${date ? `date=${date}` : ``}${store_id ? `&store_id=${store_id}` : ``}${time ? `&time=${time}` : ``}${time_unit ? `&time_unit=${time_unit}` : ``}${date_number ? `&date_number=${date_number}` : ``}`;

export const NEW_USER = "newuser/";

export const LOGIN = "login/";

export const LOGOUT = "logout/";

export const CHANGE_USERNAME = "user/change-username/";

export const FORGET_PASSWORD_VERIFY_OTP = "user/forget-password/verify-opt/";

export const FORGET_PASSWORD_URL = "user/forget-password/"

export const FORGET_CHANGE_PASSWORD = "user/forget/change-password/";

export default FILL_REPRESENTATIVE_INFO;

export const LIST_REPRESENTATIVES = "user/list-representatives/";

export const USER_IDENTITY_INFO = "user/get-identity-info/";

export const SUPERVISOR_APPROVE_ORDER = (order_uid: string | null) =>
  `supervisor/approve-order/${order_uid}`;

export const SUPERVISOR_DEFECT_ORDER = "supervisor/defect-order/";

export const SUPERVISOR_ORDER_DEFECTS = (order_uid: string | null) =>
  `supervisor/order-defects/${order_uid}`;

export const SUPERVISOR_ORDER_DEFECTS_ITEMS = "supervisor/order-defects-items/";

export const REGISTER_ORDER_SEND_OTP = "store/register-order/send-otp/";

export const REGISTER_ORDER_VERIFY_OTP = "store/register-order/verify-otp/";

export const ORDER_INITIAL_APPROVAL = "store/order/initial-approval/";

export const DEBTS_PAYMENT_ORDER_AMOUNT = "debts/payment/order-amount/";

export const DEBTS_PAYMENT_ORDER_AMOUNT_LINK =
  "debts/payment/order-amount/link/";

export const SUPERVISOR_CHEQUE_DEFECTS = (order_uid: string | null) =>
  `supervisor/cheque-defects/${order_uid}`;

export const DEBTS_PENALTY_CHEQUE_DEL = (penalty_cheque_uid: string | null) =>
  `debts/penalty-cheque/${penalty_cheque_uid}`;

export const DEBTS_DEBTS = (
  creditor_uid: string | null,
  pageNumber?: number,
  pageSize?: number,
  state?: any,
  search_query?: string,
  from_date?: any,
  until_date?: any
) =>
  `debts/${creditor_uid}/debts/?page=${pageNumber}&page_size=${pageSize}${
    search_query ? `&search_query=${search_query}` : ``
  }${state ? `&state=${state}` : ``}${
    from_date ? `&from_date=${from_date}` : ``
  }${until_date ? `&until_date=${until_date}` : ``}`;

export const SUPERVISOR_INSTALMENT_DEFECTS = (instalment_uid: string | null) =>
  `supervisor/instalment-defects/${instalment_uid}/`;

export const SUPERVISOR_GUARANTEE_DEFECTS = (
  guarantee_cheque_uid: string | null
) => `supervisor/guarantee-defects/${guarantee_cheque_uid}/`;

export const USER_HOME_CONTEXT = "user/home-context/";

export const STORE_HOME_CONTEXT = (panel_uid: string | null) =>
  `store/store-context/${panel_uid}`;

export const CREDITOR_CONTEXT = (panel_uid: string | null) =>
  `creditor/creditor-context/${panel_uid}`;

export const GET_FILE = (file: string | null | undefined) =>
  `core/get-file/${file}/`;

export const USER_ADDRESS_INQUIRY = (inputValue: string | null) =>
  `user/address-inquiry/${inputValue}/`;

export const LOAN_SEND_OTP_GUARANTOR = "loan/send-otp-guarantor/";

export const USER_OCR_ID_CARD = "user/ocr-id-card/";

export const POLICY_URL = "user/user_accept_policy/";

export const OTP_URL = "auth/verify-otp/";

export const LOGIN_URL = "auth/login/";

export const USER_PASS_LOGIN = "user/login_user_pass/";

export const PASSWORD_SETTER_URL = "user/set_pass/";

export const DEBTS_PENALTY_CHEQUE = "debts/penalty-cheque/";

export const DEBTS_PAYMENT_LINK = "debts/payment-link/";

export const DEBTS_PAYMENT_INITIAL_APPROVAL = "debts/payment-initial-approval/";

export const CREDIT_INQUIRY_VERIFY_OTP = "loan/credit-inquiry/verify-otp/";

export const CREDIT_INQUIRY_INQUIRY = (verificationOtpTicket: string | null) =>
  `loan/credit-inquiry/inquiry/${verificationOtpTicket}/`;

export const CREDIT_INQUIRY_SEND_OTP = "loan/credit-inquiry/send-otp/";

export const STORE_PRODUCT_CREATE = "store/product-create/";

export const PRODUCT_CLASSIFY_STORE_GROUP = (store_uid: string | null) =>
  `store/product-classify/store/group/${store_uid}/`;

export const DEBTS_DETAIL_DEBT = (debt_file_uid: string | null) =>
  `debts/detail-debt/${debt_file_uid}/`;

export const PRODUCT_CLASSIFY_TYPE = (product_group_uid: string | null) =>
  `store/product-classify/type/${product_group_uid}/`;

export const PRODUCT_CLASSIFY_BRAND = (product_type_uid: string | null) =>
  `store/product-classify/brand/${product_type_uid}/`;

export const PRODUCT_CLASSIFY_PRODUCT = (
  product_type_uid: string | null,
  brand_uid: string | null
) => `store/product-classify/product/${product_type_uid}/${brand_uid}/`;

export const STORE_GET_ORDER_ITEMS = (order_uid: string | null) =>
  `store/get-order-items/${order_uid}/`;

export const STORE_ORDER_STATE_LIST = "store/order-states-list/";

export const DEBTS_PENALTY_TYPES_EXPORT_ACCESS =
  "debts/penalty-types/expert-access/";

export const STORE_ADD_ORDER_ITEM = "store/add-order-item/";

export const LOAN_GET_SELECT_LOAN = (order_uid: string | null) =>
  `loan/get-select-loan/${order_uid}/`;

export const STORE_GET_INVOICE = (order_uid: string | null) =>
  `store/get-invoice/${order_uid}`;

export const STORE_APPROVE_INVOICE = (order_uid: string | null) =>
  `store/approve-invoice/${order_uid}/`;

export const LOAN_AVAILABLE_LOANS = (
  order_uid: string | null,
  activeButtonPeriod: number,
  activeButtonPayment: number,
  processedValue: any
) =>
  `loan/available-loans/?order_uid=${order_uid}&step=${activeButtonPeriod}&refund_period=${activeButtonPayment}&pre_payment=${processedValue}`;

export const STORE_ORDER_DETAIL = (order_uid: string | null) =>
  `store/order-detail/${order_uid}/`;

export const LOAN_CHEQUE_DETAILS = (order_uid: string | null) =>
  `loan/cheque-details/${order_uid}`;

export const LOAN_GUARANTEE_DETAILS = (order_uid: string | null) =>
  `loan/guarantee-details/${order_uid}`;

export const LOAN_SEND_SUPERVISOR = (order_uid: string | null) =>
  `loan/send-supervisor/${order_uid}/`;

export const STORE_UPLOAD_INVOICE = "store/upload-invoice/";

export const STORE_UPDATE_INVOICE = (order_uid: string | null) =>
  `store/update-invoice/${order_uid}/`;

export const STORE_GET_INVOICE_FILE = (order_uid: string | null) =>
  `store/get-invoice-file/${order_uid}/`;

export const STORE_STORE_BANK_INFO_CREATE = "store/store_bank_info/create/";

export const STORE_STORE_BANK_INFO_UPDATE = (store_uid: string | null) =>
  `store/store_bank_info/update/${store_uid}/`;

export const STORE_STORE_BANK_INFO_GET = (store_uid: string | null) =>
  `store/store_bank_info/get/${store_uid}/`;

export const STORE_PROMISSORY_NOTE_GET = (store_uid: string | null) =>
  `store/promissory-note/get/${store_uid}`;

export const USER_GET_IDENTITY_INFO = "user/get-identity-info/";

export const LOAN_GET_CREDIT = "loan/get-credit/";

export const USER_FILL_IDENTITY_INFO = "user/fill-identity-info/";

export const USER_IDENTITY_INFO_UPDATE = "user/identity_info/update/";

export const CORE_PROVINCE = "core/province/";

export const CORE_CITY = (id?: number | null) => `core/city/?search=${id}`;

export const CORE_CITY_NO_ID = `core/city/`;

export const STORE_STORE_INFO_CREATE = "store/store_info/create/";

export const STORE_STORE_INFO_UPDATE = (store_uid: string | null) =>
  `store/store_info_/update/${store_uid}/`;

export const STORE_STORE_GET_INFO = "store/store_get_info/";

export const STORE_GET_STORE_LICENSE = "store/get_store_license/";

export const STORE_JOB_CATEGORY_LIST = "store/job_category_list/";

export const STORE_STORE_LICENSE_CREATE = "store/store_license/create/";

export const STORE_STORE_LICENSE_UPDATE = (store_uid: string | null) =>
  `store/store_license/update/${store_uid}/`;

export const STORE_PRODUCT_GROUP = "/store/product-group/";

export const STORE_ACCEPT_CONTRACT = "store/accept-contract/";

export const LOAN_SELECT_LOAN = "loan/select-loan/";

export const CORE_GET_COMMON_FILE = (file: string | null) =>
  `core/get-common-file/${file}/`;

export const STORE_SELECT_STORE = "store/select-store/";

export const LOAN_UPLOAD_CHEQUE_IMAGE_UID = (instalment_uid: string | null) =>
  `loan/upload-cheque-image/${instalment_uid}/`;

export const LOAN_UPLOAD_CHEQUE_IMAGE = "loan/upload-cheque-image/";

export const LOAN_UPDATE_GUARANTEE_CHEQUE = (cheque_uid: string | null) =>
  `loan/update-guarantee-cheque/${cheque_uid}/`;

export const UPLOAD_GUARANTEE_CHEQUE = "loan/upload-guarantee-cheque/";

export const LOAN_VERIFY_OTP_GUARANTOR = "loan/verify-otp-guarantor/";

export const LOAN_GUARANTOR_CONFIRM = (cheque_uid: string | null) =>
  `loan/guarantor/confirm/${cheque_uid}/`;

export const LOAN_GUARANTOR_IDENTITY_UID = (guarantor_uid: string | null) =>
  `loan/guarantor/identity/${guarantor_uid}/`;

export const LOAN_GUARANTOR_IDENTITY = "loan/guarantor/identity/";

export const LIST_STORE_ORDERS = (
  store_uid: string | null,
  pageNumber: number,
  pageSize: number,
  state: any,
  sort_by: string,
  search_query: string
) =>
  `store/${store_uid}/store-orders/?page=${pageNumber}&page_size=${pageSize}${
    sort_by ? `&sort_by=${sort_by}` : ``
  }${state ? `&state=${state}` : ``}${
    search_query ? `&search_query=${search_query}` : ``
  }`;

export const SUPERVISOR_STORE_INFO = (store_uid: string | null) =>
  `supervisor/store-info/${store_uid}/`;

export const SUPERVISOR_ORDER_USER_INFO = (order_uid: string | null) =>
  `supervisor/order-user-info/${order_uid}/`;

export const SUPERVISOR_STORES_LIST = (
  page: any,
  pageSize: any,
  state: any,
  city: any,
  sort_by: string,
  search_query: string
) =>
  `supervisor/stores/?page=${page}&sort_by=${sort_by}&page_size=${pageSize}${
    state ? `&state=${state}` : ``
  }&city=${city}${search_query ? `&search_query=${search_query}` : ``}`;

export const SUPERVISOR_STORE_DEBTS = (creditor_uid: any) =>
  `supervisor/store-debts/${creditor_uid}/`;

export const STORE_SUPERVISOR_STORE_PAYMENT = "store/supervisor/store-payment/";

export const SUPERVISOR_EXCEL_STORES_DABT = (creditor_uid: string | null) =>
  `supervisor/excel/stores-debt/${creditor_uid}/`;

export const SUPERVISOR_ORDER_DETAILS = (order_uid: string | null) =>
  `supervisor/order-details/${order_uid}/`;

export const FINAL_APPROVAL_REVIEW = (order_uid: string | null) =>
  `supervisor/final-approval/${order_uid}/`;

export const CREDITORS_LIST = "creditor/creditors/";

export const LOAN_WAGE = "loan/payment/loan-wage/";

export const PRODUCT_DELIVERED = (order_uid: string | null) =>
  `store/product-delivered/${order_uid}/`;

export const SUPERVISOR_ORDER_LIST = (
  pageNumber: number,
  pageSize: number,
  state?: any,
  sort_by?: string,
  search_query?: string,
  from_date?: any,
  until_date?: any
) => `supervisor/order-list/?page=${pageNumber}&page_size=${pageSize}&sort_by=${sort_by}&search_query=${search_query}${
  state ? `&state=${state}` : ``
}
${until_date ? `&until_date=${until_date}` : ``}
${from_date ? `&from_date=${from_date}` : ``}`;

export const CREDITOR_ORDER_LIST = (
  creditor_uid: string | null,
  pageNumber: number | null,
  pageSize?: number | null,
  state?: any,
  apiSearch?: any
) =>
  `supervisor/${creditor_uid}/order-list/?page=${pageNumber}&page_size=${pageSize}&search_query=${apiSearch}${
    state ? `&state=${state}` : ``
  }`;

export const STORE_CREDITOR_ORDER_STATES = "/store/creditor-order-states/";

export const STORE_LIST_NOTIFICATIONS = (store_uid: string | null) =>
  `store/list-notifications/${store_uid}/`;

export const STORE_SEEN_NOTIFICATION = (notification_uid: string | null) =>
  `store/seen-notification/${notification_uid}`;

export const USER_NOTIFICATION = "user/notification/";

export const STORE_STORE_LICENSE = (store_uid: string | null) =>
  `store/store-license/${store_uid}/`;

export const STORE_STORE_GET_INFO_UID = (store_uid: string | null) =>
  `store/store_get_info/${store_uid}`;

export const STORE_STORES_UID = (store_uid: string | null) =>
  `store/stores/${store_uid}/`;

export const STORE_USER_ORDERS = "store/user-orders/";
export const USER_INSTALMENTS = "loan/user-instalments/";
export const USER_GUARANTEES = "loan/guarantees/";

export const STORE_ORDERS_USER = "store/orders-user/";
export const USER_ACTIVE_ORDER = "store/active-order/";

export const USER_RESIDENTIAL_INFO_UPDATE = "user/residential_info/update/";

export const USER_FILL_RESIDENTIAL_INFO = "user/fill-residential-info/";

export const USER_GET_RESIDENTIAL_INFO = "user/get-residential-info/";

export const USER_UPDATE_JOB_INFO = "user/update-job-info/";

export const USER_GET_JOB_INFO = "user/get-job-info/";

export const USER_FILL_JOB_INFO = "user/fill-job-info/";

export const USER_UPDATE_REPRESENTATIVE_INFO = (representative_uid: string) =>
  `user/update-representative-info/${representative_uid}/`;

export const LOAN_GUARANTOR_RESIDENTIAL_NO_UID = "loan/guarantor/residential/";

export const LOAN_GUARANTOR_RESIDENTIAL_WITH_UID = (guarantor_uid: string) =>
  `loan/guarantor/residential/${guarantor_uid}/`;

export const AUTH_JWTOKEN_VERIFY = "auth/jwtoken/verify/";

export const STORE_UPLOAD_IMAGE = (store_uid: string | null) =>
  `store/upload-image/${store_uid}/`;

export const STORE_UPLOAD_LOGO = (store_uid: string | null) =>
  `store/upload-logo/${store_uid}/`;

export const BANKS = "core/banks/";
export const SUPERVISOR_CREDITOR_ORDER_LIST = (
  creditor_uid: string | null,
  pageNumber: number,
  pageSize: number,
  state?:
    | "PRODUCT_DELIVERED"
    | "DOCUMENT_UPLOADED"
    | "FILE_SENT"
    | "FILE_RECEIVED"
    | "PHYSICAL_DOCUMENT_DEFECT"
    | "DOCUMENTS_VERIFIED"
    | "ORDER_PAID"
    | "COMPLETED"
    | "",
  search_query?: string,
  until_date?: any,
  from_date?: any
) => {
  let url = `supervisor/${creditor_uid}/orders/?state=${state}&page=${pageNumber}&search_query=${search_query}&page_size=${pageSize}`;

  if (from_date) {
    url += `&from_date=${from_date}`;
  }
  if (until_date) {
    url += `&until_date=${until_date}`;
  }
  url = url.endsWith("&") ? url.slice(0, -1) : url;
  return url;
};

export const SUPERVISOR_CHEQUES_AMOUNT = (order_uid: string | null) =>
  `supervisor/order-factors/${order_uid}/`;

export const SUPERVISOR_RECEIVE_FILE = () => `supervisor/receive-file/`;

export const SUPERVISOR_ORDER_USER_LIST = (
  pageNumber: number,
  pageSize: number,
  state?: any,
  apiSearch?: any
) =>
  `admin/users/?page=${pageNumber}&page_size=${pageSize}&search_query=${apiSearch}${
    state ? `&state=${state}` : ``
  }`;

export interface ChequeListOptions {
  state?: string | null;
  search_query?: string | null;
  from_date?: string | null;
  until_date?: string | null;
  sort_by?: string | null;
}
export const SUPERVISOR_CREDITOR_UID_GET_CHEQUE_LIST = (
  creditor_uid: string | null,
  from_date?: any,
  until_date?: any,
  state?: any,
  search_query?: any,
  sort_by?: any
) => {
  let url = `supervisor/${creditor_uid}/cheques/?`;
  if (state) {
    url += `state=${state}&`;
  }
  if (search_query) {
    url += `search_query=${search_query}&`;
  }
  if (from_date) {
    url += `from_date=${from_date}&`;
  }
  if (until_date) {
    url += `until_date=${until_date}&`;
  }
  if (sort_by) {
    url += `sort_by=${sort_by}`;
  }
  url = url.endsWith("&") ? url.slice(0, -1) : url;
  return url;
};
export const SUPERVISOR_APPROVAL_REVIEW = "supervisor/approval-review/";

export const SUPERVISOR_IN_BANK_CHEQUES = (
  creditor_uid: any,
  search_query?: any,
  from_date?: any,
  until_date?: any,
  sort_by?: any
) => {
  let url = `supervisor/${creditor_uid}/in-bank-cheques/?`;

  if (search_query) {
    url += `search_query=${search_query}&`;
  }

  if (from_date) {
    url += `from_date=${from_date}&`;
  }
  if (until_date) {
    url += `until_date=${until_date}&`;
  }
  if (sort_by) {
    url += `sort_by=${sort_by}`;
  }

  url = url.endsWith("&") ? url.slice(0, -1) : url;

  return url;
};

export const DEBTS_EXCEL_CHEQUES_CREDITOR_UID = (creditor_uid: string) => {
  return `debts/excel-cheques/${creditor_uid}/`;
};

export const SUPERVISOR_CHEQUES_SEND_BANK = () => {
  return `supervisor/cheques-send-bank/`;
};

export const GET_BANK_LIST = () => {
  return `core/banks/`;
};
export const SUPERVISOR_GET_IDENTITY_INFO = (user_uid: string | null) => {
  return `supervisor/identity-info/${user_uid}`;
};

export const ORDER_DEFECT_ITEMS = (order_uid: string | null) => {
  return `loan/defect-details/${order_uid}`;
};

export const USER_PROFILE = `user/profile/`;

export const USER_RESIDENTIAL_INFO = `user/get-residential-info/`;
export const USER_JOB_INFO = `user/get-job-info/`;
export const USER_REPRESENTATIVE_LIST = `user/list-representatives/`;

export const SUPERVISOR_RECIEVE_FILE = "supervisor/receive-file/";

export const DEBTS_CHEQUE_STATE = "debts/cheque-state/";

export const SUPERVISOR_IN_BANK_CHEQUE = (creditor_uid: string) => {
  return `supervisor/${creditor_uid}/in-bank-cheques/`;
};
export const SUPERVISOR_RESIDENTIAL_INFO = (user_uid: string | null) => {
  return `supervisor/residential-info/${user_uid}`;
};

export const SUPERVISOR_JOB_INFO = (user_uid: string | null) => {
  return `supervisor/job-info/${user_uid}`;
};

export const SUPERVISOR_REPRESENTATIVES_INFO = (user_uid: string | null) => {
  return `supervisor/representatives-info/${user_uid}`;
};

export const SUPERVISOR_USER_ORDERS = (user_uid: string | null) => {
  return `supervisor/user-orders/${user_uid}`;
};

export const CREDITOR_SET_CHEQUE_STATE = "creditor/set-cheque-state/";

export const DEBTS_CHEQUE_DEBT_CHEQUE_UID_CHEQUE_NOTES = (
  cheque__debt_cheque_uid: string | null
) => {
  return `debts/${cheque__debt_cheque_uid}/cheque-notes/`;
};
export const DEBTS_CHEQUE_NOTE = "debts/cheque-note/";

export const DEBTS_LIST_DETAILS_CHEQUE = (debt_file_uid: string | null) => {
  return `debts/list-details-cheque/${debt_file_uid}`;
};
export const DELETE_CHEQUE_NOTE = (note_uid: string | null) => {
  return `debts/cheque-note/${note_uid}/`;
};
export const UPDATE_CHEQUE_NOTE = (note_uid: string | null) => {
  return `debts/cheque-note/${note_uid}/`;
};
export const SUPERVISOR_STORE_REJECT = (store_uid: string | null) => {
  return `store/supervisor/store-reject/${store_uid}/`;
};
export const SUPERVISOR_STORE_VERIFY = (store_uid: string | null) => {
  return `store/supervisor/store-verify/${store_uid}/`;
};
export const DEBTS_CONFIRM_CHEQUE_PASSED = (debt_cheque_uid: string | null) => {
  return `debts/confirm-cheque-passed/${debt_cheque_uid}/`;
};
export const DEBTS_FILE_RETURNED_CHEQUE = (file_uid: string | null) => {
  return `debts/file-returned-cheque/${file_uid}/`;
};

export const SEND_LEGAL = (debt_file_uid: string | null) => {
  return `debts/send-legal/${debt_file_uid}/`;
};

export const DEBTS_RETURNED_CHEQUE = "debts/returned-cheque-doc/";
export const CREATE_LEGAL_STATEMENT = () => {
  return `debts/legal-statement/`;
};

export const CREATE_CLAIM_STATEMENT = () => {
  return `debts/claim-statement/`;
};

export const DEBTS_SEND_TO_BANK_STATE = (debt_cheque_uid: string | null) => {
  return `debts/debt-financial-state/${debt_cheque_uid}/`;
};

export const PHYSICAL_APPROVAL = "supervisor/physical-approval/";

export const PHYSICAL_FINAL_APPROVAL = (order_uid: string | null) =>
  `supervisor/physical-final-approval/${order_uid}/`;

export const CORE_COMMON_QUESTION_LIST = "core/common-question/list/";

export const DEBTS_RETURNED_BANK_CHEQUES = (
  creditor_uid: string | null,
  pageNumber?: any | null,
  pageSize?: any | null,
  search_query?: any | null
) =>
  `debts/${creditor_uid}/returned-bank-cheques/?search_query=${search_query}&page=${pageNumber}&page_size=${pageSize}`;

export const DEBTS_RETUNRNED_CHEQUE_TAG_NUMBER = (
  debt_cheque_uid: string | null
) => {
  return `debts/retunrned-cheque-tag-number/${debt_cheque_uid}/`;
};

export const SUPERVISOR_PHYSICAL_REVIEW = (order_uid: string | null) =>
  `physical-review/${order_uid}/`;

export const SUPERVISOR_APPROVAL_CHEQUE = (instalment_uid: string) =>
  `supervisor/approve-cheque/${instalment_uid}`;

export const SUPERVISOR_APPROVAL_GUARANTEE_CHEQUE = (
  guarantee_cheque_uid: string
) => `supervisor/approve-guarantee-cheque/${guarantee_cheque_uid}`;

export const SUPERVISOR_PHYSICAL_REVIEW_DEFECT = "supervisor/physical-review/";

export const PHYSICAL_GET_ORDER_REVIEW = (order_uid: string) =>
  `supervisor/physical-review/${order_uid}/`;

export const SUPERVISOR_REVIEW_ORDER = (order_uid: string | null) =>
  `supervisor/review-order/${order_uid}/`;

export const GET_POLICY = "user/user_get_policy/";

export const SUPERVISOR_REMOVE_CHEQUE_BACK_IMAGE = (
  instalment_uid: string | null
) => `supervisor/remove-cheque-back-image/${instalment_uid}/`;

export const DEBTS_DETAILS_GUARANTEE = (debt_file_uid: string | null) =>
  `debts/details-guarantee/${debt_file_uid}/`;

export const USER_USER_GET_POLICY = `user/user_get_policy/`;

export const DEBTS_PAYMENT_RECEIPT = (receipt_file_uid: string | null) =>
  `debts/payment-receipt/${receipt_file_uid}/`;

export const CORE_CONTRACT_DOWNLOAD = (order_uid: string | null) =>
  `core/contract-download/${order_uid}/`;

export const STORE_ORDER_CONTRACT = `store/order-contract/`;

export const STORE_ORDER_DOWNLOAD = (order_uid: string | null) =>
  `store/order-download/${order_uid}/`;

export const STORE_PRODUCT_DELIVERY_NOTE = `store/product-delivery-note/`;

export const STORE_UPLOAD_CONTRACTS = `store/upload-contracts/`;

export const STORE_DOCUMENT_DELIVERY = (order_uid: string | null) =>
  `store/document-delivery/${order_uid}`;

export const STORE_GET_ORDER_FILES = (order__order_uid: string | null) =>
  `store/get-order-files/${order__order_uid}/`;

export const STORE_COSTUMER_ARREARS = (store_uid: string | null) =>
  `store/${store_uid}/costumer-arrears/`;

export const STORE_INVOICE_DOWNLOAD = (order_uid: string | null) =>
  `store/invoice-download/${order_uid}/`;
export const CANCEL_ORDER = `/store/cancel-order/`;
