import { BrowserRouter } from "react-router-dom";
import "./App.css";
import RoutesWithAnimation from "./provider/RoutesWithAnimation";
import { theme } from "./theme/Theme";
import { ThemeProvider } from "@mui/material";
import RtlComponent from "./components/RtlComponent";
import 'react-toastify/dist/ReactToastify.css';
import "./i18n";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RtlComponent>
        <BrowserRouter>
            <RoutesWithAnimation />
        </BrowserRouter>
      </RtlComponent>
    </ThemeProvider>
  );
}

export default App;
