import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
  modalHeightVariants,
  modalVariants,
} from "../../../animation/RoutesAnimations";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/elements/ButtonComponent";

const  DeleteWorkType = ({
  onClose,
  selectRef,
  data,
  deleteFunction
}: any) => {

  const navigate = useNavigate();




  return ReactDOM.createPortal(
    <>
      <motion.div
        variants={modalVariants}
        initial="initial"
        animate="final"
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-gray-500 bg-opacity-50"
      >
        <motion.div
          layout
          
          variants={modalHeightVariants}
          initial="initial"
          animate="final"
          className={`relative w-[400px] overflow-hidden bg-white rounded-lg shadow pb-4 px-4 `}
        >
          <motion.div
            layout={"position"}
            initial="initial"
            animate="final"
            className="relative"
          >
            <div ref={selectRef} className="flex flex-col w-full">
              <div className=" flex items-end justify-end rounded-t pt-2">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                >
                  <img src="/icons/x.svg" alt="icon" width={20} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* code here */}
              <div className="flex w-full flex-col justify-center items-center">
                  <img className="w-[32px]" src="/icons/danger-red.svg" alt="not found" />
              </div>
              <div className="flex flex-col w-full justify-center items-center p-6">
                <p className="text-gray-950 text-BodyText/16/Medium">از حذف این خدمت مطمعن هستید؟</p>
                <p className="text-red-500 text-BodyText/12/Bold text-center">وقت های قبلی رزرو شده‌ی این خدمت حذف نخواهند شد!</p>
              </div>
              <div className="flex w-full justify-center items-center gap-4">
                <ButtonComponent
                variant="outlined"
                onClick={onClose}
                >لغو</ButtonComponent>

                <ButtonComponent
                onClick={deleteFunction}
                >
                  تایید
                </ButtonComponent>
              </div>


            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>,
    document.getElementById("modal-root")!
  );
};

export default DeleteWorkType;
