import moment from "jalali-moment";

export function convertToJalali(dateString: string): string | undefined {
  if (dateString) {
    const jalaliDate = moment(dateString, "YYYY-MM-DD")
      ?.locale("fa")
      ?.format("YYYY/MM/DD");
    return jalaliDate;
  }
  return undefined;
}
