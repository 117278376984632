import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import myLangDetector from "./lib/languageDetector";

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myLangDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fa",
    debug: false,
    detection: {
      order: ["myLangDetector"],
    },
  });

export default i18n;
