import { Route, Routes, useLocation, useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import User from "../layouts/User";
import { axiosPrivate } from "../api/axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import LocationProvider from "./LocationProvider";
import { AUTH_JWTOKEN_VERIFY } from "../api/apiClient";
import Lottie from "react-lottie";
import animationData from "../jsons/LoadingBg.json";
import SiteHeader from "./SiteHeader";
import Customer from "../components/Pages/Cunstomer";
import MainPage from "../components/Pages/MainPage";
import Auth from "../components/Pages/Auth";
import InDevelopment from "../components/Pages/InDevelopment";
import UserNotification from "../UserNotification";
import PaymentReport from "../components/Pages/PaymentReport";
import AnimatedBtn from "../components/common/AnimatedBtn";
import Roles from "../components/Pages/Roles";
import AboutUs from "../components/Pages/Aboutus";
import FourZeroFour from "./FourZeroFour";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  renderer: "svg",
};

const Fallback = () => (
  <div className="min-h-screen flex items-center justify-center min-w-full">
    <Lottie options={defaultOptions} height={100} width={100} />
  </div>
);
export default function RoutesWithAnimation() {
  const location = useLocation();
  const access_token = localStorage.getItem("access_token");
  const [formNo, setFormNo] = useState<number>(1);

  const navigate = useNavigate();
  const [enteredUser, setEnteredUser] = useState(
    localStorage.getItem("access_token")
  );

  const verifyToken = async () => {
    if (access_token) {
      try {
        const response = await axiosPrivate.post(
          AUTH_JWTOKEN_VERIFY,
          { token: access_token },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${access_token}`,
            },
            withCredentials: true,
          }
        );
        if (response?.status >= 200 && response?.status < 300) {
          setEnteredUser(localStorage.getItem("access_token"));
        }
      } catch (error) {
        setEnteredUser("");
        localStorage.clear();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const [timeTableRefresh, setTimeTableRefresh] = useState<boolean>(false)
  const queryClient = new QueryClient();

  return (
    <LocationProvider>
      <AnimatePresence>
              <QueryClientProvider client={queryClient}>
              <SiteHeader />
                <Routes location={location} key={location.key}>
                  <Route
                    path="/user/:customer_uuid"
                    element={
                      <User
                      />
                    }
                  >
                  </Route>
                  <Route
                    path="/"
                    element={
                      <MainPage
                      />
                    }
                  >

                  </Route>
                  
                  <Route
                    path="/customers"
                    element={
                      <>
                      <UserNotification setTimeTableRefresh={setTimeTableRefresh} timeTableRefresh={timeTableRefresh} />
                      <Customer timeTableRefresh={timeTableRefresh} />
                      </>
                    }
                  >
                  </Route>

                  <Route
                    path="/login"
                    element={
                      <Auth />
                    }
                  >
                  </Route>

                  <Route
                  path="/indevelopment"
                  element={
                    <InDevelopment />
                  }
                  >

                  </Route>

                  <Route
                  path="/aboutus"
                  element={
                    <AboutUs />
                  }
                  >

                  </Route>

                  <Route
                  path="/roles"
                  element={
                    <Roles />
                  }
                  >

                  </Route>

                  <Route
                  path="/payment-report/"
                  element={
                    <PaymentReport />
                  }
                  >

                  </Route>
                  <Route
                  path="*"
                  element={
                    <FourZeroFour />
                  }
                  >

                  </Route>
                </Routes>
              </QueryClientProvider>
      </AnimatePresence>
    </LocationProvider>
  );
}
