import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {
  modalHeightVariants,
  modalVariants,
} from "../../../animation/RoutesAnimations";
import customZ from "../../../utils/setZodCustomMessages";
import { useState } from "react";
import { axiosPrivate } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { ORDER_INITIAL_APPROVAL, TIME_TABLE_CUSTOMERS } from "../../../api/apiClient";
import ButtonComponent from "../../../components/elements/ButtonComponent";
import { useForm } from "react-hook-form";
import { WORK_TYPE } from "../../../api/apiClient";
import { zodResolver } from "@hookform/resolvers/zod";
import InputComponent from "../../elements/InputComponent";
import { notEmptyAndPersianField, onlyNumberSchema } from "../../../utils/customSchemas";

const  TimeDeletePopup = ({
    onClose,
    selectRef,
    mutation,
    itemTime,
    time_unit,
    date_number,
    user_pay_before,
    setTimeTableData,
    walletMutate
}: any) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [percent, setPercent] = useState<any>(0.4);

  async function deleteFunction (time: any, time_unit: any, date_number: any) {
    setLoading(true);

    try {
      const response = await axiosPrivate.delete(
        TIME_TABLE_CUSTOMERS(undefined, undefined, time=time, time_unit=Number(time_unit), date_number=String(date_number)),           {
            withCredentials: true,
          }
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (user_pay_before) { 
            setTimeTableData(response?.data?.table)
        } else
          {
            mutation.mutate();
            if (walletMutate)
              walletMutate.mutate()
            onClose()
          }
  
      }
      setLoading(false);
    } catch (error) {
      if ((error as any)?.response) {
        const responseStatus: any = (error as any).response?.status;
        if (responseStatus === 500) {
            console.log('server 500 error')
        }
      } else {
        console.log('server error')
      }
    } finally {
      setLoading(false)
    }
  }

  return ReactDOM.createPortal(
    <>
      <motion.div
        variants={modalVariants}
        initial="initial"
        animate="final"
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-gray-500 bg-opacity-50"
      >
        <motion.div
          layout
          
          variants={modalHeightVariants}
          initial="initial"
          animate="final"
          className={`relative w-[400px] overflow-hidden bg-white rounded-lg shadow pb-4 px-4 `}
        >
          <motion.div
            layout={"position"}
            initial="initial"
            animate="final"
            className="relative"
          >
            <div ref={selectRef} className="flex flex-col w-full">
              <div className=" flex items-end justify-end rounded-t pt-2">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="text-gray-400 bg-transparent rounded-lg w-8 h-8 inline-flex justify-start items-center"
                >
                  <img src="/icons/x.svg" alt="icon" width={20} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* code here */}
              <div className="flex w-full flex-col justify-center items-center">
                  <img className="w-[32px]" src="/icons/danger-red.svg" alt="not found" />
              </div>
              <div className="flex flex-col w-full justify-center items-center p-6">
                <p className="text-gray-950 text-BodyText/16/Medium">از حذف این نوبت مطمعن هستید؟</p>
                <p className="text-red-500 text-BodyText/12/Bold text-center">مبلغ پرداختی برای رزرو این نوبت عودت نخواهد یافت!</p>
                <p className="text-yellow-500 text-BodyText/12/Medium text-center">برای جا به جایی نوبت ها میتوانید آن‌ها را بکشید.</p>
              </div>
              <div className="flex w-full justify-center items-center gap-4">
                <ButtonComponent
                variant="outlined"
                onClick={onClose}
                >لغو</ButtonComponent>

                <ButtonComponent
                isClicked={loading}
                onClick={() => deleteFunction(itemTime, time_unit, date_number)}
                >
                  تایید
                </ButtonComponent>
              </div>


            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>,
    document.getElementById("modal-root")!
  );
};

export default TimeDeletePopup;
