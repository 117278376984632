import { useRef } from "react";
import MapScreen from "../../components/map/Map";


const TopLeft = ({profileData, myRef}: any) => {


  
    return (
        <>
          <div className="max-sm:hidden sm:hidden lg:flex flex-col w-[50%] justify-center items-center gap-4"> 
            <p className="text-primary-300  text-HeadLine/28/Bold">{profileData?.work_name}</p> 
            <p className="text-primary-900">آدرس :</p>
            <p className="text-primary-900 text-center">{profileData?.address}</p>
            <MapScreen lng={profileData?.lng} lat={profileData?.lat} justShow />
            <button onClick={() => myRef.current.scrollIntoView()} className="bg-primary-400 w-[30%] h-[40px] rounded-[10px] text-primary-50 text-HeadLine/24/Regular">درخواست نوبت</button>
            <div className="w-[64px] h-[16px] gap-2 justify-center items-start flex flex-row">
              {/* <img src="/icons/telegram_icon.svg" alt="telegram icon" className="w-[16px] h-[16px]"/>
              <img src="/icons/instagram_icon.svg" alt="instagram icon" className="w-[16px] h-[16px]"/>
              <img src="/icons/phone_icon.svg" alt="phone icon" className="w-[16px] h-[16px]"/> */}
            </div>
          </div>
        </>
    );
  
  };
  
  export default TopLeft;
  