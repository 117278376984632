import { useEffect, useState } from "react";
import ButtonComponent from "../elements/ButtonComponent";
import useAddComma from "../hooks/useAddComma";
import PaymentPopup from "./popups/PaymentPopup";
import { axiosPrivate } from "../../api/axios";
import { PAYMENT_HISTORY } from "../../api/apiClient";
import { useMutation } from "@tanstack/react-query";
import useJalaliDateConverter from "../../hooks/useJalaliDateConverter";
import { convertDateTimeToJalali } from "../../utils/useDateTimeConvertToJalali";


const Wallet = ({ data }: any) => {

  const [paymentPopup, setPaymenyPopup] = useState<boolean>(false);
  const [paymentHistory, setPaymentHistory] = useState<any>(undefined);
  const addCama = useAddComma();

  function onClose() {
    setPaymenyPopup(false)
  }

  const getPaymentHistory = () =>
    axiosPrivate
      .get(
        PAYMENT_HISTORY,

        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.payment_history);

  const paymentHistoryMutate = useMutation({
    mutationFn: getPaymentHistory,
    onSuccess(data, variables, context) {
      setPaymentHistory(data);
    },
  });

  useEffect(() => {
    paymentHistoryMutate.mutate();
  }, [])

  return (
    <div className="flex flex-col justify-center items-center w-full mt-6">
      <div className="flex flex-col items-end bg-gray-50 min-w-[300px] max-sm:w-auto sm:w-auto lg:w-[600px] p-4 max-sm:h-auto sm:h-auto lg:h-[200px] rounded-3xl">

        <div className="flex gap-2 w-full justify-end items-end lg:border-b border-gray-200">

          <p className={`${data?.credit < 20000 ? (data?.credit < 0 ? `text-red-600` : `text-yellow-600`) : `text-green-600`} text-BodyText/16/Medium`}>{addCama(data?.credit)}</p>
          <p className="text-gray-500 text-BodyText/16/Medium">تومان</p>
          <img src="icons\wallet-icon.svg" alt="trash icon" className="w-[48px] h-[48px]" />
        </div>
        <div className="flex w-full max-sm:items-center sm:items-center lg:items-start justify-start mt-2">
          <p className="text-gray-900 text-right text-Body-OneLine/16/Bold">مدیریت کیف پول:</p>
        </div>
        <div className="flex items-center justify-center w-full gap-6 mt-6">
          <ButtonComponent
            hasIcon
            disabled
            variant="outlined"
            icon={
              <img className=" w-4 h-4" src="/icons/wallet-arrow-icon.svg" alt="not found" />
            }
            className=""
          >برداشت</ButtonComponent>

          <ButtonComponent
            hasIcon
            onClick={() => setPaymenyPopup(true)}
            variant="outlined"
            icon={
              <img className=" w-4 h-4" src="/icons/square-plus-icon.svg" alt="not found" />
            }
            className=""
          >واریز</ButtonComponent>

        </div>
        {paymentPopup ? (<PaymentPopup onClose={onClose} />) : null}
      </div>
      <div className="flex flex-col items-end min-w-[300px] gap-3 max-sm:w-auto sm:w-auto lg:w-[600px] p-4 max-sm:h-auto sm:h-auto lg:h-[200px] mt-2 rounded-2xl">
        {paymentHistory?.map((item: any, index: number) => (
          <div className={`flex w-full justify-between items-end p-2 ${item?.description === 'CUSTOMER' || item?.description === 'PRE_PAYMENT' ? `border-green-100` : `border-red-100`} border rounded-lg`}>
            <div className="flex flex-col justify-start items-start w-1/2">
              <div className="flex w-[110px] gap-1"><p className="text-gray-900 text-BodyText/12/Regular">{addCama(item?.amount / 10)}</p><img src='/icons/tooman-black.svg' alt='not found' /></div>
              <div className="flex w-[110px] gap-1"><p className="text-primary-500 text-BodyText/12/Regular">{item?.description === 'CUSTOMER' ? 'واریز' : (item?.description === 'PRE_PAYMENT' ? 'واریز(بیعانه)' : (item?.description === 'PRE_REQUEST' ? 'رزرو وقت (مشتری)' : (item?.description === 'INTERNAL' ? 'رزرو وقت' : ('کارمزد رزرو شو'))))}</p>{item?.description === 'CUSTOMER' || item?.description === 'PRE_PAYMENT' ? (<img src="/icons/deposit-blue.svg" />) : (<img src="/icons/picked-up-blue.svg" />)}</div>
            </div>
            <div className="flex flex-col justify-end items-end w-1/2">
              <div className="flex w-[100px] gap-1"><p className="text-gray-700 text-BodyText/12/Regular"><span>{String((item?.created_at).split('T')[1]).slice(0, 5)} {convertDateTimeToJalali(item?.created_at)}</span></p></div>
            </div>
          </div>
        ))}
      </div>
    </div>

  )
}

export default Wallet;