import z from "./setZodCustomMessages";
import {
  invalidLandLineNumberMessage,
  invalidNationalCodeMessage,
  invalidPhoneNumberMessage,
  invalidPostalCodeMessage,
  onlyNumberMessage,
  persianCharacterMessage,
  requiredMessage,
  multiSelectMessage,
  invalidLengthMessage,
  invalidShabaLengthMessage,
} from "./validationCustomMessages";

function checknationalId(code: string): boolean {
  const L = code.length;

  if (L < 8 || parseInt(code, 10) === 0) return false;

  code = ("0000" + code).substr(L + 4 - 10);

  if (parseInt(code.substr(3, 6), 10) === 0) return false;

  const c = parseInt(code.substr(9, 1), 10);
  let s = 0;

  for (let i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);

  s = s % 11;

  return (s < 2 && c === s) || (s >= 2 && c === 11 - s);
}

const isPersian = (value: string) => /^[\u0600-\u06FF\s]+$/.test(value);

export const onlyNumberSchema = z
  .string()
  .refine((value) => value !== "", requiredMessage)
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  });

export const mobileNumberSchema = z
  .string()
  .refine((value) => value !== "", {
    message: requiredMessage,
  })
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })

  .refine((value) => value[0] === "0", {
    message: invalidPhoneNumberMessage,
  })
  .refine((value) => value.length === 11, {
    message: invalidPhoneNumberMessage,
  });

export const secondMobileNumberSchema = z
  .string()
  .refine(
    (value) => {
      if (value === null || value === undefined) {
        return true;
      }
      if (value === "") return true;
      const mobileNumberPattern = /^09\d{9}$/;
      return mobileNumberPattern.test(value);
    },
    { message: "شماره موبایل وارد شده معتبر نیست" }
  )
  .optional();

export const postalCodeSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length === 10, {
    message: invalidPostalCodeMessage,
  })
  .refine(
    (value) =>
      new RegExp("\\b(?!((\\d)\\2{3}))[13-9]{4}[1346-9][013-9]{5}\\b").test(
        value
      ),
    {
      message: "کد پستی شما معتبر نمیباشد.",
    }
  );

export const nationalCodeSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })

  .refine((value) => checknationalId(value), {
    message: "کد ملی معتیر نمیباشد.",
  })
  .refine((value) => value.length === 10, {
    message: invalidNationalCodeMessage,
  });

  export const priceSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => (Number(value) >= 50000), {
    message: 'حداقل مقدار = 50,000 تومان',
  })

export const phonenumberSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length === 11, {
    message: invalidPhoneNumberMessage,
  });

export const landlinenumberSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length === 8, {
    message: invalidLandLineNumberMessage,
  });

export const landlinenumberSchemaJob = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length >= 5, {
    message: invalidLandLineNumberMessage,
  });

export const fullTelNumberSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length >= 8, {
    message: invalidLandLineNumberMessage,
  });

export const notEmptyAndPersianField = z
  .string()

  .refine((value) => isPersian(value), {
    message: persianCharacterMessage,
  });

export const optonalStringInput = z.string().optional();

export const notEmptyField = z.string().refine((value) => value !== "", {
  message: requiredMessage,
});
export const notEmptyFieldText = z
  .string()
  .refine((value) => value !== "", {
    message: requiredMessage,
  })
  .optional();

export const notEmpty = z.string().refine((value) => value === undefined, {
  message: requiredMessage,
});

export const notEmptyListField = z
  .string()
  .array()
  .refine((value) => value.length !== 0, {
    message: multiSelectMessage,
  });

export const notEmptyDate = z
  .date()
  .or(
    z.string().refine((value) => !!Date.parse(value), {
      message: "Invalid date format",
    })
  )
  .refine((value) => !!value, {
    message: requiredMessage,
  });

export const notEmptyFile = z
  .object({
    file: z.instanceof(File),
  })
  .refine((value) => value.file.size > 0, {
    message: requiredMessage,
  });

export const idCardFieldSchema = z.unknown().refine(
  (value) => {
    if (typeof value === "string") {
      return value.trim() !== "";
    } else if (typeof value === "object" && value instanceof File) {
      return value.size > 0 && value.size <= 5 * 1024 * 1024;
    }
    return false;
  },
  {
    message: "Invalid id_card value",
  }
);

export const idCardFieldSchemaOptional = z
  .unknown()
  .refine(
    (value) => {
      if (typeof value === "string") {
        return value.trim() !== "";
      } else if (typeof value === "object" && value instanceof File) {
        return value.size > 0 && value.size <= 5 * 1024 * 1024;
      }
      return false;
    },
    {
      message: "Invalid id_card value",
    }
  )
  .optional();

export const bankNumberSchema = z
  .string()
  .refine((value) => value.trim() !== "", {
    message: requiredMessage,
  })
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length <= 18, {
    message: invalidLengthMessage,
  });
export const shabaBankNumberSchema = z
  .string()
  .refine((value) => value.trim() !== "", {
    message: requiredMessage,
  })
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length <= 24, {
    message: invalidShabaLengthMessage,
  });

export const landlinenumberWithoutPrefixSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: onlyNumberMessage,
  })
  .refine((value) => value.length === 8, {
    message: invalidLandLineNumberMessage,
  });

export const IBANSchema = z.string().refine(
  (value) => {
    return value.length === 24;
  },
  {
    message: `شماره شبا 24 رقم می‌باشد.`,
  }
);

export const SayadiNumberSchema = z.string().refine(
  (value) => {
    return value.length === 16;
  },
  {
    message: `شماره صیادی 16 رقم می‌باشد.`,
  }
);

export const SeriesNumberSchema = z.string().refine(
  (value) => {
    return value.length >= 3 && value.length <= 6;
  },
  {
    message: `شماره سری 3 تا 6 رقم می‌باشد.`,
  }
);

export const ChequeNumberSchema = z.string().refine(
  (value) => {
    return value.length >= 6 && value.length <= 8;
  },
  {
    message: `شماره سریال 6 تا 8 رقم می‌باشد.`,
  }
);

export const nationalIdSchema = z.string().refine(
  (value) => {
    return value.length > 0 && value.length === 10;
  },
  {
    message: `کدملی صحیح نمی‌باشد.`,
  }
);
