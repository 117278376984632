import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  globalErrorHandler?: boolean;
}

interface APIErrorResponse {
  message: string;
  errors?: any;
  data?: any;
  details?: any;
}

export const BASE_URL = process.env.REACT_APP_API_KEY;

export const BASE_URL_APP_VERSION = `${process.env.REACT_APP_API_KEY}`;

// function handleError(error: AxiosError<APIErrorResponse>) {
//   const config = error.config as CustomAxiosRequestConfig;
//   if (config.globalErrorHandler === false) {
//     return;
//   }

//   if (error.response) {
//     switch (error?.response?.status) {
//       case 400:
//         if (error.response.data?.details[0]) {
//           toast.error(` ${error.response.data?.details[0].message}`);
//         } else {
//           toast.error(
//             `${error.response.data?.message}
//          `
//           );
//         }
//         break;
//       case 401:
//         if (error.response.data?.details[0]) {
//           toast.error(` ${error.response.data?.details[0].message}`);
//         } else {
//           toast.error(
//             "شما به این بخش دسترسی ندارید. " + error.response.data?.message
//           );
//         }

//         break;
//       case 403:
//         if (error.response.data?.details[0]) {
//           toast.error(` ${error.response.data?.details[0].message}`);
//         } else {
//           toast.error("مشکلی پیش آمده است. " + error.response.data?.message);
//         }
//         break;
//       case 404:
//         if (error.response.data?.details[0]) {
//           toast.error(` ${error.response.data?.details[0].message}`);
//         } else {
//           toast.error(
//             "خطای 404: یافت نشد. " + error.response.data?.message ||
//               (error?.name as string)
//           );
//         }
//         break;
//       case 500:
//         console.error(
//           "Error 500: Internal Server Error. " + error.response.data?.message
//         );
//         toast.error("ارتباط با سرور به درستی برقرار نشد");
//         break;
//       default:
//         toast.error(
//           `Error ${error.response?.status}: ${error.response.statusText}`
//         );
//     }
//     return;
//   }
//   if (error.request) {
//     toast.error("error . request");
//     return;
//   }
//   if (error.message) {
//     toast.error("error . message");
//     return;
//   }
//   if (error.code === "ECONNABORTED") {
//     toast.error("تایم اوت.");
//   }
//   if (error.code === "ERR_NETWORK") {
//     toast.error("لطفا اتصال به اینترنت خود را بررسی نمایید.");
//     return;
//   } else {
//     console.error(error.message || "An unknown error occurred.");
//   }

//   console.error("Global Error Handler:", error);
// }

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     handleError(error);
//     return Promise.reject(error);
//   }
// );

const axiosInstance = axios.create({
  baseURL: BASE_URL_APP_VERSION,
  timeout: 60000,
});

export const axiosMulti = axios.create({
  baseURL: BASE_URL_APP_VERSION,
  headers: { "Content-Type": "multipart/form-data" },
  withCredentials: true,
  timeout: 60000,
});

export const axiosPrivateMoreTime = axios.create({
  baseURL: BASE_URL_APP_VERSION,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 60000,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL_APP_VERSION,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 60000,
});

export const axiosPrivateTest = axios.create({
  baseURL: BASE_URL_APP_VERSION,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 60000,
});

export const axiosAuthorization = axios.create({
  baseURL: BASE_URL_APP_VERSION,
  headers: {
    "Content-Type": "application/json",
    // prettier-ignore
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    // prettier-ignore
  },
  withCredentials: true,
  timeout: 60000,
});

const instances = [axiosInstance, axiosMulti, axiosPrivate, axiosAuthorization];
// instances.forEach((instance) => {
//   instance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       handleError(error);
//       return Promise.reject(error);
//     }
//   );
// });

export default axiosInstance;
