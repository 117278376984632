import moment from "jalali-moment";

export function convertDateTimeToJalali(dateString: string): string | undefined {
  if (dateString) {
    const jalaliDate = moment(dateString.split('T')[0], "YYYY-MM-DD")
      ?.locale("fa")
      ?.format("YYYY/MM/DD");
    return jalaliDate;
  }
  return undefined;
}
