import React, { useEffect, useRef, useState } from 'react';



const ScrollTimePicker = ({time, setTime, error, label}: any) => {



  return (

    <div className='flex w-full flex-col justify-center items-center'>
    <div className="relative flex-col justify-start items-start flex">
    <p className='text-gray-900 w-full text-right text-BodyText/16/Medium'>
        {label}
      </p>
        <input value={time} onChange={(e) => setTime(e.target.value)} type="time" id="time" className={`${error ? `bg-red-50` : `bg-primary-50` } border leading-none ${error ? `border-red-300` : `border-primary-300` } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}   required />
    </div>
    </div>
  );
};

export default ScrollTimePicker;