import { ReactNode } from "react";
import { AnimatePresence } from "framer-motion";

interface LocationProviderProps {
  children: ReactNode;
}

export default function LocationProvider({ children }: LocationProviderProps) {
  return <AnimatePresence>{children}</AnimatePresence>;
}
