import React, { useState, ReactElement, useEffect } from "react";

interface TabProps {
  label: string;
  icon?: any;
  children: React.ReactNode;
  className?: string;
}

interface TabsProps {
  children: ReactElement<TabProps>[];
  onTabClick?: (tabLabel: string) => void; // Adding a new prop for custom click handler
  activeTab?: string; // Optional controlled active tab
  setActiveTab?: (label: string) => void; // Optional setter for controlled active tab
  py?: number;
}

const Tabs = ({children, onTabClick, py = 4, activeTab: externalActiveTab, setActiveTab: setExternalActiveTab,
}: TabsProps) => {
  const [internalActiveTab, setInternalActiveTab] = useState(
    children[0].props.label
  );
  const isControlled =
    externalActiveTab !== undefined && setExternalActiveTab !== undefined;
  const activeTab = isControlled ? externalActiveTab : internalActiveTab;
  const setActiveTab = isControlled
    ? setExternalActiveTab
    : setInternalActiveTab;

  useEffect(() => {
    // If the component is controlled, ensure the internal state is synced with external prop.
    if (isControlled && externalActiveTab !== internalActiveTab) {
      setInternalActiveTab(externalActiveTab);
    }
  }, [externalActiveTab, isControlled, internalActiveTab]);

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    newActiveTab: string
  ) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
    if (onTabClick) {
      onTabClick(newActiveTab); // Call the custom function with the tab label
    }
  };

  return (
    <div className="">
      {/* <div className="flex "> */}
      <div className="flex sticky top-0 bg-white py-4">
        {children.map((child) => (
          <button
            key={child.props.label}
            className={`${
              activeTab === child.props.label
                ? "rounded-bb-ends border-primary-500 text-primary-500 font-bold text-Body-OneLine/16/Bold"
                : "border-b-2 border-gray-300 "
            } flex-1 text-Body-OneLine/16/Regular text-gray-400 py-2`}
            onClick={(e) => handleClick(e, child.props.label)}
          >
            {!child.props.icon ? (<>{child.props.label}</>) : (<>{child.props?.icon && (<>{child.props?.icon}</>)}</>)}
          </button>
        ))}
      </div>
      <div className={`py-${py}`}>
        {children.map((child) => {
          if (child.props.label === activeTab) {
            return <div key={child.props.label}>{child.props.children}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, icon,  children, className }: TabProps) => {
  return (
    <div data-label={label} className={className || "hidden"}>
      {children}
    </div>
  );
};

export { Tabs, Tab };
