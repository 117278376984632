import { sellerHeader } from "../../animation/RoutesAnimations";
import { Outlet } from "react-router-dom";
import { motion, useAnimation, useTransform, useViewportScroll } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from 'react'
import React from "react";
import { Tab, Tabs } from "../../components/user-dashboard-tabs/Tabs";
import useAddComma from "../hooks/useAddComma";
import InputComponent from "../elements/InputComponent";
import ButtonComponent from "../elements/ButtonComponent";
import QRCode from "react-qr-code";
import Footer from "../../provider/Footer";
import { indexof } from "stylis";
import {Helmet} from 'react-helmet';

const MainPage = () => {




  const [isActive, setActive] = useState([false, false, false, false, false])

  const toggleText = (index: number) => {
    let sample = isActive
    sample[index] = !isActive[index]
    setActive([...sample])
  }

  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [0, 1], [0.5, 1.8]);

  const motionVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const FAQ = [
    {question: 'در چه ساعتی از شبانه روز می‌شود از این برنامه استفاده کرد؟', answer: 'شما می‌توانید در ۲۴ ساعت شبانه روز و ۷ روز هفته به نوبت‌های خود دسترسی داشته باشید و نوبت جدید رزرو کنید.'},
    {question: 'مشتری‌ها چطور می‌توانند نوبت رزرو کنند؟', answer: ' مشتری‌ها از طریق لینک پروفایل خدمات‌دهندگان یا اسکن کردن QRcode مربوط به پروفایل خدمات‌دهندگان می‌توانند به صفحه آن‌ها دسترسی داشته باشند و نوبت خود را رزرو کنند.'},
    {question: 'آیا ارسال پیامک‌های تاییدیه رزرو نوبت مشتری هزینه دارد؟', answer: 'خیر تماماً ارسال پیامک‌ها از طریق سایت رایگان می‌باشد.'},
    {question: 'رزرو نوبت مشتری چقدر زمان می‌برد؟', answer: 'سریع‌ترین روند ثبت‌نوبت برای مشتری‌های شما در نظر گرفته شده که با چند کلیک ساده نوبت‌ها رزرو می‌شود.'},
    {question: 'چه مقدار بیعانه برای رزرو نوبت در نظر گرفته شده؟', answer: '   قسمت قوانین سایت مطالعه شود.'},
  ]


  const control = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  
  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className="overflow-x-hidden min-h-screen "
    >
      <Helmet>
        <title>سایت رزرو شو</title>
        <meta name="description" content="سامانه رزرو نوبت رزرو شو" />
        <link rel="canonical" href="https://rezerv-sho.ir/" />
      </Helmet>

      <div className="flex flex-col bg-primary-800 w-full  items-center justify-center py-32 gap-20">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          className=" flex flex-row max-sm:w-11/12 sm:w-11/12 lg:w-10/12 justify-center items-center bg-white rounded-3xl">
          <img className="max-sm:w-full sm:w-full lg:w-full h-auto rounded-4xl justify-center items-center p-4 " src="/images/banner1.png" alt="not found" />
        </motion.div>
        <div className="flex max-sm:flex-col sm:flex-col lg:flex-row w-full items-center justify-around bg-white max-sm:px-4 sm:px-10 md:px-16 lg:px-20 py-10 mt-32 max-sm:gap-10 sm:gap-10 lg:gap-4">
          {/* 1 */}
          <motion.div style={{ scale }} className="flex flex-col justify-center items-center max-sm:w-4/5 sm:w-4/5 lg:w-1/5 bg-primary-600 min-h-60 py-8 px-1 rounded-4xl">
            <img className="w-10 h-10 " src="/icons/fast.svg" alt="fast not found" />
            <p className="text-white text-Title/16/Regular border-b-2 border-white mt-4 ">سریع و آسان</p>
            <div className="w-8/12 flex justify-center items-center  text-center">
              <p className="text-white text-BodyText/14/Medium mt-4">به راحتی با چند کلیک نوبت خود را رزرو کنید.</p>
            </div>
          </motion.div>
          {/* 2 */}
          <motion.div style={{ scale }} className="flex flex-col justify-center items-center max-sm:w-4/5 sm:w-4/5 lg:w-1/5 bg-primary-600 min-h-60 py-8 px-1 rounded-4xl">
            <img className="w-10 h-10 " src="/icons/24hour.svg" alt="24hour not found" />
            <p className="text-white text-Title/16/Regular border-b-2 border-white mt-4 ">شبانه روزی</p>
            <div className="w-8/12 flex justify-center items-center text-center">
              <p className="text-white text-BodyText/14/Medium mt-4">در هر ساعتی از شبانه روز میتوانید نوبت خود را در <a href="https://rezerv-sho.ir" className="text-primary-800 text-BodyText/16/Bold"> رزرو شو </a> ثبت کنید.</p>
            </div>
          </motion.div>
          {/* 3 */}
          <motion.div style={{ scale }} className="flex flex-col justify-center items-center max-sm:w-4/5 sm:w-4/5 lg:w-1/5 bg-primary-600 min-h-60 py-8 px-1 rounded-4xl">
            <img className="w-10 h-10 " src="/icons/cash.svg" alt="cash not found" />
            <p className="text-white text-Title/16/Regular border-b-2 border-white mt-4">پرداخت بیعانه</p>
            <div className="w-8/12 flex justify-center items-center  text-center">
              <p className="text-white text-BodyText/14/Medium mt-4">برای رزرو نوبت ما کمترین مقدار هزینه برای بیعانه را در نظر گرفتیم.</p>
            </div>
          </motion.div>
          {/* 4 */}
          <motion.div style={{ scale }} className="flex flex-col justify-center items-center max-sm:w-4/5 sm:w-4/5 lg:w-1/5 bg-primary-600 min-h-60 py-8 px-1 rounded-4xl">
            <img className="w-10 h-10 " src="/icons/mail.svg" alt="mail not found" />
            <p className="text-white text-Title/16/Regular border-b-2 border-white mt-4 ">ارسال پیامک</p>
            <div className="w-8/12 flex justify-center items-center  text-center">
              <p className="text-white text-BodyText/14/Medium mt-4">با ارسال پیامک نوبت خود را در <a href="https://rezerv-sho.ir" className="text-primary-800 text-BodyText/16/Bold"> رزرو شو </a> فراموش نکنید.</p>
            </div>
          </motion.div>
        </div>
        <div className="flex flex-col  items-center  w-full">

          <div className="  w-3/5 ">
            <p className="text-white text-HeadLine/28/Bold text-center">مراحل ثبت نوبت در <a href="https://rezerv-sho.ir" className="text-primary-100 text-HeadLine/28/Bold">رزرو شو </a></p>
          </div>
          <div className="flex max-sm:flex-col sm:flex-col md:flex-row justify-center max-sm:items-center sm:items-center md:items-start w-full max-sm:p-4 sm:p-10 md:p-12 lg:p-16 gap-6">
            <div className="flex h-[650px] flex-col max-sm:w-[100%] sm:w-[85%] md:w-[50%] items-center gap-4 max-sm:p-0 sm:p-0 md:p-20 border-4 border-primary-50 rounded-2xl border-opacity-80">
              {/* <img className="w-14 h-14 mt-2" src="/icons/user-white.svg" alt="icon not found" /> */}
              <p className="text-Title/20/Bold text-white border-b-[1px] border-gray-50 border-opacity-50 mt-4">مراحل ثبت نوبت صاحب کار در <a href="https://rezerv-sho.ir" className="text-primary-500 text-Title/20/Bold">رزرو شو </a></p>
              <div className="flex flex-row w-3/4 gap-3 items-center mt-4">
                <img className="w-7 h-7" src="/icons/number1-white.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">ثبت نام</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number2.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">ویرایش اطلاعات</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number3.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">افزودن خدمات</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number4.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">ثبت نوبت</p>
              </div>

              <div className="flex flex-row w-3/4  items-center px-8 mt-4">
                <p className="text-Title/20/Bold text-white">ویژگی ها</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number1-white.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">ثبت نوبت نامحدود</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number2.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">ضمانت بازگشت وجه</p>
              </div>

            </div>



            {/* ghesmat karbar */}
            <div className="flex h-[650px] flex-col max-sm:w-[100%] sm:w-[85%] md:w-[50%] items-center gap-4 max-sm:p-0 sm:p-0 md:p-20 border-4 border-primary-50 rounded-2xl border-opacity-80">
              {/* <img className="w-14 h-14 mt-2" src="/icons/user-white.svg" alt="icon not found" /> */}
              <p className="text-Title/20/Bold text-white border-b-[1px] border-gray-50 border-opacity-50 mt-4">مراحل ثبت نوبت کاربران <a href="https://rezerv-sho.ir" className="text-primary-500 text-Title/20/Bold">رزرو شو </a></p>
              <div className="flex flex-row w-3/4 gap-3 items-center mt-4">
                <img className="w-7 h-7" src="/icons/number1-white.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">وارد شدن به فروشگاه</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number2.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">انتخاب خدمت و زمان </p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number3.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">پرداخت بیعانه</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number4.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">ثبت نوبت</p>
              </div>

              <div className="flex flex-row w-3/4  items-center px-8 mt-4">
                <p className="text-Title/20/Bold text-white">ویژگی ها</p>
              </div>
              <div className="flex flex-row w-3/4 gap-3 items-center">
                <img className="w-7 h-7" src="/icons/number1-white.svg" alt="notfound" />
                <p className="text-white text-BodyText/16/Medium">ارسال پیامک</p>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>

        <motion.ul    
        ref={ref}
        // initial="hidden"
        animate={control}
        // variants={container} 
        className="bg-white w-full flex flex-col gap-4 p-8 max-sm:px-5 sm:px-10 md:px-12 lg:px-40">
          <div className="flex justify-center mt-5">
            <p className="text-gray-700 text-HeadLine/28/Bold">سوالات متداول</p>
          </div>

          {FAQ.map((item: any, index: number) => (<>
          <motion.li transition={{ duration: 1 }} key={index} variants={motionVariants} className="flex flex-row justify-start items-center gap-3 w-11/12 mt-5">
          <img onClick={() => toggleText(index)} className="w-6 h-6 cursor-pointer" src="/icons/question.svg" alt="question icon" />
          <h2 className="text-gray-600 text-BodyText/16/Bold cursor-pointer" onClick={() => toggleText(index)} >{item.question}</h2>
        </motion.li>

        {isActive[index] && (
          <motion.div
          initial={{ opacity: 0, height: 5 }}
          animate={{ opacity: 1, height: 25 }}
          transition={{ duration: 2 }}
          className="flex w-11/12 overflow-hidden border-r-2 border-dotted border-gray-500">
            <p className="text-gray-500 text-BodyText/14/Regular mr-2">
              {item.answer}
            </p>
          </motion.div>
        )}</>
          ))}


        </motion.ul>

      </div>
      <Footer />
    </motion.div>
  );
};

export default MainPage;
