import { sellerHeader } from "../../animation/RoutesAnimations";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from 'react'
import React from "react";
import { Tab, Tabs } from "../../components/user-dashboard-tabs/Tabs";
import useAddComma from "../hooks/useAddComma";
import InputComponent from "../elements/InputComponent";
import ButtonComponent from "../elements/ButtonComponent";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import customZ from "../../utils/setZodCustomMessages";
import { notEmptyAndPersianField, notEmptyField, onlyNumberSchema } from "../../utils/customSchemas";
import { Password } from "primereact/password";
import UserIcon from "../Icons";
import NewPassword from "./NewPassword";
import { watch } from "fs";

interface SignupInterface {
    first_name: string;
    last_name: string;
    phone_number: string;
  }

const Signup = ({setSignupMode}: any) => {

    const SignupSchema = customZ.object({
        first_name: notEmptyAndPersianField,
        last_name: notEmptyAndPersianField,
        phone_number: notEmptyField,
      });
    
    const {
        handleSubmit,
        control,
        watch,
        reset,
        getValues,
        formState: { errors, isValid },
      } = useForm<SignupInterface>({
        resolver: (data, context, options) => {
          return zodResolver(SignupSchema)(data, context, options);
        },
      });

  const [passMode, setPassMode] = useState(false);


  function SubHandler (data: any) {
    setPassMode(true)
  }

  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className=""
    >
            {!passMode ? (<form onSubmit={handleSubmit(SubHandler)} className="py-4 flex flex-col gap-4  items-center justify-center">
                    <p className=" w-full text-center text-gray-900 text-Title/20/Bold ">
                        ثبت نام خدمات دهندگان
                    </p>
                        <InputComponent
                        defaultValue={getValues('first_name')}
                        name="first_name"
                        maxLength={11}
                        label="نام"
                        type="text"
                        size="regular"
                        errors={errors?.first_name}
                        control={control}
                        svgIcon={<UserIcon />}
                         />

                        <InputComponent
                        defaultValue={getValues('last_name')}
                        name="last_name"
                        maxLength={11}
                        label="نام خانوادگی"
                        type="text"
                        size="regular"
                        errors={errors?.last_name}
                        control={control}
                        svgIcon={<UserIcon />}
                         />

                    <InputComponent
                      defaultValue={getValues('phone_number')}
                      maxLength={11}
                      type="tel"
                      size="regular"
                    //   editable={true}
                      label="شماره موبایل خود را وارد کنید:"
                      name="phone_number"
                      control={control}
                      errors={errors?.phone_number}
                      svgIcon={
                        <img src="../icons/phone-outline.svg" alt="icon" />
                      }
                    />


                <ButtonComponent
                    // disabled={isButtonDisabled}
                    // isClicked={isButtonDisabled}
                    // onClick={() => setPassMode(true)}
                    type="submit"
                    variant="filled"
                    className="w-full h-[40px]"
                >
                    انتخاب رمز ورود
                </ButtonComponent>

                <ButtonComponent
                    // isClicked={isButtonDisabled}
                    onClick={() => {setSignupMode(false); reset()}}
                    type="button"
                    variant="outlined"
                    className="w-full h-[40px]"
                >
                     ورود
                </ButtonComponent>

            </form>) : (<>
            <NewPassword reset={reset} setSignupMode={setSignupMode} setPassMode={setPassMode} getSignupData={getValues} />
            </>)}
    </motion.div>
  );
};

export default Signup;
