import { sellerHeader } from "../../animation/RoutesAnimations";
import { Outlet, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from 'react'
import React from "react";
import { Tab, Tabs } from "../../components/user-dashboard-tabs/Tabs";
import useAddComma from "../hooks/useAddComma";
import InputComponent from "../elements/InputComponent";
import ButtonComponent from "../elements/ButtonComponent";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import customZ from "../../utils/setZodCustomMessages";
import { notEmptyAndPersianField, notEmptyField, onlyNumberSchema } from "../../utils/customSchemas";
import { Password } from "primereact/password";
import { LOGIN } from "../../api/apiClient";
import { axiosPrivate } from "../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

interface LoginInterface {
    phone_number: string;
    password: string;
  }

  
const Login = ({setSignupMode}: any) => {

    const [loading, setLoading] = useState(false);
    const navigator = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies()
    const [passwordConfShow, setPasswordConfShow] = useState<boolean>(false)
    const LoginSchema = customZ.object({
        phone_number: notEmptyField,
        password: notEmptyField,
      });
    

    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors, isValid },
      } = useForm<LoginInterface>({
        resolver: (data, context, options) => {
          return zodResolver(LoginSchema)(data, context, options);
        },
      });



  async function loginHandler (data: any) {
    setLoading(true);

    try {

      const response = await axiosPrivate.post(
        LOGIN, data,     {withCredentials: true,}
      );
      if (response?.status >= 200 && response?.status < 300) {
        toast('خوش آمدید')    
        setCookie('session', true, {maxAge: 172800})
        navigator('/customers')
      } else{
        console.log('error')
      }
      setLoading(false);
    } catch (error) {
      toast.warning('رمز عبور یا شماره تلفن اشتباه است')
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className=""
    >
                <form onSubmit={handleSubmit(loginHandler)} className="py-4 flex flex-col gap-4 items-center justify-center">
                <p
            className="flex text-center text-gray-900 text-Title/20/Bold "
          >
            ورود خدمات دهندگان
          </p>
                <InputComponent
                      maxLength={11}
                      type="tel"
                      size="regular"
                      editable={true}
                      label="شماره موبایل خود را وارد کنید:"
                      name="phone_number"
                      control={control}
                      errors={errors?.phone_number}
                      svgIcon={
                        <img src="../icons/phone-outline.svg" alt="icon" />
                      }
                    />
                      <InputComponent
                        name="password"
                        maxLength={20}
                        label="رمز عبور"
                        type={`${passwordConfShow ? `text` : `password`}`}
                        size="regular"
                        errors={errors?.password}
                        control={control}
                        svgIcon={
                          <img
                            src="./icons/lock-outline.svg"
                            alt="lastName icon"
                            width={16}
                          />
                        }
                        hasLeftIcon
                        leftIcon={        <img
                          width={18}
                          onClick={() => setPasswordConfShow(!passwordConfShow)}
                          src={`${
                            passwordConfShow
                              ? `./icons/eye-outline.svg`
                              : `./icons/eye-slash-outline.svg`
                          }`}
                          alt=""
                        />}
                         />

                <ButtonComponent
                    // disabled={isButtonDisabled}
                    isClicked={loading}
                    type="submit"
                    variant="filled"
                    className="w-full h-[40px]"
                >
                    ورود
                </ButtonComponent>

                <ButtonComponent
                    // disabled={isButtonDisabled}
                    // isClicked={isButtonDisabled}
                    onClick={() => setSignupMode(true)}
                    type="button"
                    variant="outlined"
                    className="w-full h-[40px]"
                >
                    ثبت نام
                </ButtonComponent>

                </form>
    </motion.div>
  );
};

export default Login;
