

import { useMutation } from "@tanstack/react-query";
import { STORE_SETTINGS, TIME_TABLE_CUSTOMERS } from "../../api/apiClient";
import { axiosPrivate } from "../../api/axios";
import { useEffect, useState } from "react";
import MainTable from "../../pages/admin-dashboard/admin-tabs/users/MainTable";
import ButtonComponent from "../../components/elements/ButtonComponent";
import React from "react";
import animationData from "../../../src/jsons/clock-yellow.json";
import Lottie from "react-lottie";
import { backOut } from "framer-motion";
import TableHeader from "../../pages/admin-dashboard/admin-tabs/users/TableHeader";
import RequestPopup from './popups/RequestPopup';
import TimeDeletePopup from "./popups/TimeDeletePopup";
import TableDetailPopup from "./popups/TableDetailPopup";
const CustomerTimeTable = ({
  profileData,
  timeTableRefresh,
  workTypeData,
  walletMutate
}: any) => {

  const [timeTableData, setTimeTableData] = useState<any>(undefined);
  const [loadingTimeTable, setLoadingTimeTable] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [tableTime, setTableTime] = useState<any>(undefined);
  const [storeName, setStoreName] = useState('ساویز');
  const [inputDate, setInputDate] = useState<any>(undefined)
  const [price, setPrice] = useState<any>(undefined)
  const [service, setService] = useState<any>(undefined)
  const [timeUnit, setTimeUnit] = useState<any>(undefined)
  const [timeDiff, setTimeDiff] = useState<any>(undefined);
  const [detailPopup, setDetailPopup] = useState<boolean>(false);
  const [detailPopupData, setDetailPopupData] = useState<any>(undefined);
  const [selectrdTime, setSelectedTime] = useState<any>(undefined);
  const [settingsData, setSettingsData] = useState<any>(undefined);

  const simpleColJson = [
    { header: 'بازه زمانی', field: 'timeInterval' },
    { header: "نام خدمت", field: "name" },
    { header: "نام رزرو شده", field: "user_fullname" },
    { header: "شماره رزرو شده", field: "user_phone_number" },
  ];

  const phoneSimpleColJson = [
    { header: 'بازه زمانی', field: 'timeInterval' },
    // { header: "نام خدمت", field: "name" },
    // { header: "نام رزرو کننده", field: "user_fullname" },
    // { header: "شماره رزرو کننده", field: "user_phone_number" },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };


  const getSettings = () =>
    axiosPrivate
      .get(
        STORE_SETTINGS(),
        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.customer_settings);

  const settingsMutate = useMutation({
    mutationFn: getSettings,
    onSuccess(data, variables, context) {
      setSettingsData(data);
    },

  });

  useEffect(() => {
    settingsMutate.mutate();
  }, [])

  function timeCalculator2(time: any, time_unit: any) {
    var min_unit1 = time * (time_unit - 1)
    var hours1 = Math.floor(min_unit1 / 60)
    var minutes1 = min_unit1 % 60
    hours1 = hours1 + 6
    var min_unit2 = time * (time_unit)
    var hours2 = Math.floor(min_unit2 / 60)
    var minutes2 = min_unit2 % 60
    hours2 = hours2 + 6
    return `${hours1}:${minutes1},${hours2}:${minutes2}`
  }

  function timeRangesConflict(range1: string, range2: string) {
    // Parse the input ranges
    let [start1, end1] = range1.split(',').map(time => convertToMinutes(time));
    let [start2, end2] = range2.split(',').map(time => convertToMinutes(time));

    // Check for conflict
    return start1 < end2 && start2 < end1;
}

// Helper function to convert 'HH:MM' time format to minutes
function convertToMinutes(time: any) {
    let [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
}

  function timeIsInFree (FreeTime: string, Iindex: number, tableTime: number) {
    let tableTimeString = timeCalculator2(tableTime, Iindex + 1)
    return timeRangesConflict(FreeTime, tableTimeString)
  }


  const detailBody = (data: any, rowIndex: any) => {

    const classes = `${data.status === 'ACTIVE' ? (`border-red-600 text-red-500 hover:bg-red-50`) : (data.status === 'NONE') ? (`border-green-200 text-green-800 hover:bg-green-50`) : (data.status === 'PENDING') ? (`border-yellow-200 text-yellow-500 hover:bg-yellow-50`) : (`border-red-600 text-red-500 hover:bg-red-50`)}`
    return (
      <React.Fragment>
        <p className="flex flex-1 w-[160px] px-2.5">
          <ButtonComponent
            onClick={() => { if (data?.status === 'NONE') { setPopup(true); setTimeUnit(data.time_unit); setSelectedTime(data?.time) } else if (data?.status === 'ACTIVE' || data?.status === 'CONFLICT') { setTimeUnit(data.time_unit); setSelectedTime(data.time); setDeletePopup(true) } }}
            disabled={data?.status === 'REST'}
            hasIcon
            icon={<>
              {data.status === "ACTIVE" ? (<img
                className=" h-[18px] w-[18px]"
                src="./icons/x.svg"
                alt="not found"
              />) : (data.status === "NONE" ? (<>
                <img
                  className=" h-[24px] w-[24px]"
                  src="./icons/tik.svg"
                  alt="not found"
                />
              </>) : (data.status === "PENDING" ? (<>
                <div className="flex flex-col w-full justify-center">
                  <Lottie options={defaultOptions} height={18} width={18} />
                </div>
              </>) : (<img
                className=" h-[18px] w-[18px]"
                src="./icons/x.svg"
                alt="not found"
              />)))}
            </>
            }
            bgColor=""
            className={`${classes}`}
            variant={"outlined"}
          >
            {data.status === "NONE" ? 'ثبت نوبت' : (data.status === "ACTIVE") ? 'حذف نوبت' : (data.status === "PENDING") ? 'تایید نوبت' : 'حذف نوبت'}
          </ButtonComponent>
        </p>
      </React.Fragment>
    );
  };

  const phoneDetailBody = (data: any, rowIndex: any) => {

    const classes = `${data.status === 'ACTIVE' ? (`border-red-600 text-red-500 hover:bg-red-50`) : (data.status === 'NONE') ? (`border-green-200 text-green-800 hover:bg-green-50`) : (data.status === 'PENDING') ? (`border-yellow-200 text-yellow-500 hover:bg-yellow-50`) : (`border-red-600 text-red-500 hover:bg-red-50`)}`

    return (
      <React.Fragment>
        <p className="flex flex-1 w-[100px] px-2.5">
          {/* <ButtonComponent
              onClick={() => {if(data?.status === 'NONE') {setPopup(true); setTimeUnit(data?.time_unit)} else if (data?.status === 'ACTIVE') {setDeletePopup(true)}}}
              // disabled
              hasIcon
              icon={<>
                {data.status === "ACTIVE" ? (<img
                  className=" h-[18px] w-[18px]"
                  src="./icons/x.svg"
                  alt="not found"
                />) : (data.status === "NONE" ? (<>
                <img
                  className=" h-[24px] w-[24px]"
                  src="./icons/tik.svg"
                  alt="not found"
                />
                </>) : (data.status === "PENDING" ? (<>
                  <div className="flex flex-col w-full justify-center">
                      <Lottie options={defaultOptions} height={18} width={18} />
                    </div>
                </>) : (<img
                  className=" h-[18px] w-[18px]"
                  src="./icons/x.svg"
                  alt="not found"
                />)))}
                </>
              }
              bgColor=""
              className={`${classes}`}
              variant={"outlined"}
            >
              {data.status === "NONE" ? 'ثبت نوبت' : (data.status === "ACTIVE") ? 'حذف نوبت' : (data.status === "PENDING") ? 'تایید نوبت' : 'حذف نوبت'}
            </ButtonComponent> */}
          <ButtonComponent
            onClick={() => { setDetailPopup(true); setDetailPopupData(data) }}
          >جزئیات</ButtonComponent>
        </p>
      </React.Fragment>
    );
  };

  const getTimeTable = () =>
    axiosPrivate
      .get(
        TIME_TABLE_CUSTOMERS(inputDate),
        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.table);

  const timeTableMutate = useMutation({
    mutationFn: getTimeTable,
    onSuccess(data, variables, context) {
      let tableD = []
      let dataIndex = 0
      var last = false
      for (let i = Math.ceil(120 / tableTime); i < 1020 / tableTime; i++) {
        if (data?.length === 0) {
          if (settingsData?.is_morning_active && timeIsInFree(settingsData?.morning_free_times, i ,tableTime)) {

            tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
          } else if (settingsData?.is_noon_active && timeIsInFree(settingsData?.noon_free_times, i ,tableTime)) {
            tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
          } else if (settingsData?.is_night_active && timeIsInFree(settingsData?.night_free_times, i ,tableTime))
          {
            tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
          }else {
          tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "NONE", time: tableTime, time_unit: i + 1 })
          }
        } else {


          var confd = false
          for (let k = 0; k < data?.length; k++) {
            if (data[k].time !== tableTime) {
              confd = true
              break
            }
          }
          var confd2 = false
          for (let k = 0; k < data?.length; k++) {
            if (data[k].time === tableTime) {
              confd2 = true
              break
            }
          }


          if (confd) {
            // tableD.push({store_name : storeName, timeInterval: timeCalculator(tableTime, i+1), status : "NONE", time: tableTime, time_unit: i+1})
            
            var time_conflict = false
            // // all_day_times = Time_Table.objects.filter(sid=customer_sid,date=day_date)
            // // for item in all_day_times:

            for (let k = 0; k < data?.length; k++) {

              var minList = []
              for (let j = data[k].time * (data[k].time_unit - 1); j < data[k].time * data[k].time_unit; j++) {
                minList.push(j)
              }

              for (let j = tableTime * (i); j < tableTime * (i + 1); j++) {
                if (minList.includes(j)) {
                  dataIndex = k
                  time_conflict = true
                  break
                }

              }

            }



            if (time_conflict) {
              if (data[dataIndex].time !== tableTime) {
                tableD.push({ store_name: storeName, name: data[dataIndex].name, status: "CONFLICT", timeInterval: timeCalculator(tableTime, i + 1), time: data[dataIndex].time, time_unit: data[dataIndex].time_unit, user_fullname: data[dataIndex].user_fullname, user_phone_number: data[dataIndex].user_phone_number })
              } else {
                tableD.push({ ...data[dataIndex], timeInterval: timeCalculator(tableTime, i + 1) })
              } time_conflict = false
            }
            // else if (dataIndex + 1 < data.length) {

            //   if ((i + 1) !== data[dataIndex + 1].time_unit){
            //     //newhere
            //     var time_conflict2 = false

            //     var minList = []
            //     for (let j = data[dataIndex + 1].time * (data[dataIndex + 1].time_unit - 1) ; j < data[dataIndex + 1].time * data[dataIndex + 1].time_unit ; j ++) {
            //       minList.push(j)
            //     }

            //     for (let j = tableTime * (i + 1) ; j < tableTime * (i + 2) ; j ++) {
            //       console.log(j,'j')
            //       if (minList.includes(j)) {

            //           time_conflict2 = true
            //           break
            //         }

            //     }

            //     if (time_conflict2) {
            //       tableD.push({store_name : storeName, name: data[dataIndex + 1].name, status : "CONFLICT", timeInterval: timeCalculator(tableTime, i+1), time: data[dataIndex + 1].time, user_fullname: data[dataIndex + 1].user_fullname, user_phone_number: data[dataIndex + 1].user_phone_number})
            //     } else {
            //       tableD.push({store_name : storeName, timeInterval: timeCalculator(tableTime, i+1), status : "NONE", time: tableTime, time_unit: i+1})
            //     }
            //     dataIndex = dataIndex + 1;
            //   }
            //   else {
            //     tableD.push({...data[dataIndex + 1], timeInterval: timeCalculator(tableTime, i+1)})
            //     if (dataIndex + 2 < data.length) {
            //       dataIndex = dataIndex + 2;
            //     } else {
            //       dataIndex = dataIndex + 1;
            //     }

            //   }
            // }
            else {
              if (settingsData?.is_morning_active && timeIsInFree(settingsData?.morning_free_times, i ,tableTime)) {

                tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
              } else if (settingsData?.is_noon_active && timeIsInFree(settingsData?.noon_free_times, i ,tableTime)) {
                tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
              } else if (settingsData?.is_night_active && timeIsInFree(settingsData?.night_free_times, i ,tableTime))
              {
                tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
              }else {
              tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "NONE", time: tableTime, time_unit: i + 1 })
              }
            }
          }
          else if (confd2) {

            var check = false
            for (let k = 0; k < data?.length; k++) {
              if (data[k].time === tableTime) {
                if (data[k].time_unit === i + 1) {
                  tableD.push({ ...data[k], timeInterval: timeCalculator(tableTime, i + 1) })
                  check = true
                  break
                }
              }
            }
            if (!check) {
              if (settingsData?.is_morning_active && timeIsInFree(settingsData?.morning_free_times, i ,tableTime)) {

                tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
              } else if (settingsData?.is_noon_active && timeIsInFree(settingsData?.noon_free_times, i ,tableTime)) {
                tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
              } else if (settingsData?.is_night_active && timeIsInFree(settingsData?.night_free_times, i ,tableTime))
              {
                tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "REST", time: tableTime, time_unit: i + 1 })
              }else {
              tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "NONE", time: tableTime, time_unit: i + 1 })
              }
            }

            
          }
          else {
            tableD.push({ ...data[dataIndex], timeInterval: timeCalculator(tableTime, i + 1) })

          }

          var inTime = false;

          // if (dataIndex + 1 < data.length && data[dataIndex].time * data[dataIndex].time_unit < tableTime * (i + 1)) {
          //   dataIndex = dataIndex + 1;
          // }
        }
      }

      setTimeTableData(tableD);
      setLoadingTimeTable(false);
    },
    onMutate() {
      setLoadingTimeTable(true);
    },
    onError() {
      setLoadingTimeTable(false);
    },
  });

  useEffect(() => {
    if (inputDate !== undefined && tableTime !== undefined)
      timeTableMutate.mutate();
  }, [timeTableRefresh, inputDate, tableTime])

  function onClose() {
    setPopup(false);
    setDeletePopup(false);
    setDetailPopup(false);
  }

  function dateSuber() {
    var today = new Date().getTime();
    var dat = new Date(inputDate).getTime();
    const diffTime = (dat - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setTimeDiff(diffDays)
  }

  useEffect(() => {
    dateSuber()
  }, [inputDate])


  function timeCalculator(time: any, time_unit: any) {
    var min_unit1 = time * (time_unit - 1)
    var hours1 = Math.floor(min_unit1 / 60)
    var minutes1 = min_unit1 % 60
    hours1 = hours1 + 6
    var min_unit2 = time * (time_unit)
    var hours2 = Math.floor(min_unit2 / 60)
    var minutes2 = min_unit2 % 60
    hours2 = hours2 + 6
    return `${hours1}:${Number(minutes1)} تا ${hours2}:${Number(minutes2)}`
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <p className="text-gray-900 text-BodyText/16/Bold mb-4">جدول زمان بندی</p>

      {(profileData?.name === '---' || profileData?.address === '---' || workTypeData?.length === 0) ? (
        <div className="flex m-auto w-2/3 justify-center items-center p-6 bg-yellow-100 border border-yellow-300 rounded-md">
          {workTypeData?.length === 0 ?
            (<p className="text-yellow-500 text-BodyText/16/Regular">لطفا از منو تنظیمات کسب و کار، خدمات خود را اضافه نمایید.</p>)
            :
            (<p className="text-yellow-500 text-BodyText/16/Regular">لطفا از منو پروفایل اطلاعات خود را تکمیل نمایید.</p>)}
        </div>
      ) : (
        <>
          <TableHeader setService={setService} setPrice={setPrice} workTypeData={workTypeData} setTableTime={setTableTime} setInputDate={setInputDate} />
          <div className="max-sm:hidden sm:hidden md:flex lg:flex xl:flex">
            <MainTable
              notCheckbox
              pageNumber={1}
              dataKey=""
              Data={timeTableData || []}
              loadingData={loadingTimeTable}
              hasRowNum
              simpleColsJson={simpleColJson}
              // setSelectedDataUseState={setSelectedCustomers}
              // selectedDataUseState={selectedCustomers}
              stateCol
              stateColData={{ header: "وضعیت", field: "state.name" }}
              detailCol
              detailBody={(data: any, { rowIndex }: any) =>
                detailBody(data, rowIndex)
              } />
          </div>
          <div className="max-sm:flex sm:flex md:hidden lg:hidden xl:hidden">
            <MainTable
              notCheckbox
              pageNumber={1}
              dataKey=""
              Data={timeTableData || []}
              loadingData={loadingTimeTable}
              // hasRowNum
              simpleColsJson={phoneSimpleColJson}
              // setSelectedDataUseState={setSelectedCustomers}
              // selectedDataUseState={selectedCustomers}
              stateCol
              stateColData={{ header: "وضعیت", field: "state.name" }}
              detailCol
              detailBody={(data: any, { rowIndex }: any) =>
                phoneDetailBody(data, rowIndex)
              } />
          </div>
        </>
      )}
      {popup && <RequestPopup walletMutate={walletMutate} customer={true} price={price} store_name={profileData?.name} inputDate={inputDate} date_number={timeDiff} time={tableTime} time_unit={timeUnit} name={service} mutation={timeTableMutate} onClose={onClose} />}
      {deletePopup && <TimeDeletePopup setTimeTableData={setTimeTableData} inputDate={inputDate} date_number={timeDiff} itemTime={selectrdTime} time_unit={timeUnit} mutation={timeTableMutate} onClose={onClose} />}
      {detailPopup && <TableDetailPopup setSelectedTime={setSelectedTime} setPopup={setPopup} setTimeUnit={setTimeUnit} setDeletePopup={setDeletePopup} onClose={onClose} data={detailPopupData} />}
    </div>
  )
}

export default CustomerTimeTable;

