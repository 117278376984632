import { sellerHeader } from "../animation/RoutesAnimations";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from 'react'
import React from "react";
import { Tab, Tabs } from "../components/user-dashboard-tabs/Tabs";
import useAddComma from "../components/hooks/useAddComma";
import InputComponent from "../components/elements/InputComponent";
import { useNavigate } from "react-router-dom";
import animationData from "../../src/jsons/new-user.json";
import { axiosPrivate } from "../api/axios";
import { LOGOUT } from "../api/apiClient";
import Lottie from "react-lottie";
import AboutUs from "../components/Pages/popups/AboutUs";
import { useCookies } from 'react-cookie';
import '../components/Pages/bordercss.css';
import { toast } from "react-toastify";
const SiteHeader = () => {
  
  const addCama = useAddComma();
  const [loading, setLoading] = useState(false);
  const [aboutUsPopup, setAboutUsPopup] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies()

  
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    renderer: "svg",
  };

  useEffect(() => {
    console.log(cookies, 'lo')
  }, [cookies])
  function onClose () {
    setAboutUsPopup(false)
  }

  async function logoutHandler () {
    setLoading(true);

    try {

      const response = await axiosPrivate.get(
        LOGOUT,     {withCredentials: true,}
      );
      if (response?.status >= 200 && response?.status < 300) {
            removeCookie('session')
            toast('با موفقیت خارج شدید')
            navigate('/')
      } else{
        console.log('error')
      }
      console.log(response);
      setLoading(false);
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className=""
    >
        <div className="flex w-full h-10 bg-gray-50 justify-between items-center lg:px-32 p-6 py-8">
        <div className="flex items-center justify-center gap-4 w-[150px]">
            { window.location.href !== 'https://rezerv-sho.ir/' ? (<p onClick={() => navigate('/')} className=" text-gray-800 text-BodyText/12/Regular cursor-pointer">صفحه‌ی اصلی</p>) : null}
            { window.location.href === 'https://rezerv-sho.ir/' && cookies.session ? (<p onClick={() => navigate('/customers')} className=" text-gray-800 text-BodyText/12/Regular cursor-pointer">پنل کاربری</p>) : null}
            {/* <p onClick={() => setAboutUsPopup(true)} className=" text-gray-800 text-BodyText/12/Regular cursor-pointer">درباره ما</p> */}
            {/* <p className=" text-gray-800 text-BodyText/12/Regular">تماس با ما</p> */}
        </div>
        {/* <div className=" w-[160px] lg:hidden max-sm:flex sm:flex justify-center items-center mt-2">
          <img className="h-4 w-4" src="/icons/menu-burger-blue.svg" alt="not found" />
        </div> */}
        <div className="flex ">
          <img onClick={() => navigate('/')} className="w-10 h-10 cursor-pointer" src="/icons/rezervsho-logo.svg" alt="not found" />
        </div>
        {!window.location.href .includes('https://rezerv-sho.ir/user/') ? (
                  <div className="flex items-center justify-center gap-3 w-[150px]">
                  {!cookies.session ? ( 
                    <div className="flex w-[100px] justify-center items-center rounded-md p-[1.5px] example-2 z-10">
                      <div className="flex w-[100px] justify-center items-center p-1 rounded-md bg-gray-50 z-20">
                      <p onClick={() => navigate('/login')} className="text-gray-500 text-BodyText/12/Medium cursor-pointer">ورود/ثبت نام</p>
                      </div>
                    </div>
                    
                  ) :
                  (<p onClick={logoutHandler} className="text-red-400 text-BodyText/12/Medium cursor-pointer">خروج</p>)}
      
                  {/* {loginStatus ? 
                  (<img className="w-6 h-6 rounded-[50%]" src="/images/happy.png" alt="not found" />) 
                  : 
                  (<img onClick={() => setNewU(!newu)} className="w-6 h-6 rounded-[50%]" src="/images/sad.png" alt="not found" />)} */}
              </div>
        ) : null}


        {aboutUsPopup && <AboutUs onClose={onClose} />}
        </div>

    </motion.div>
  );
};

export default SiteHeader;
