import React, { useState } from 'react';
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import animationData from "../../../src/jsons/animated-icon-wallet.json";

const AnimatedBtn = ({onClick}: any) => {

    const defaultOptions = {
        animationData: animationData,
        renderer: "svg",
      };

    return (
            <div onClick={onClick} className='flex cursor-pointer flex-col w-[223px] h-[70px] p-2 bg-white border border-primary-100 justify-start items-start overflow-hidden relative rounded-br-lg rounded-tr-lg rounded-bl-lg'>
                <div className='flex gap-0'>
                <div className='flex gap-1 bg-gray-50 z-50 bg-opacity-50 px-2 py-1 rounded-md'>
                    <div>
                        <Lottie options={defaultOptions} height={18} width={18}/>
                    </div>
                    <p className='text-primary-700 text-Body-OneLine/14/Bold'>پرداخت امن</p>
                </div>
                <div className='flex z-50 px-2 py-1 rounded-md'>
                    <p className='text-yellow-400 text-Body-OneLine/14/Bold'>درگاه زرین‌پال</p>
                </div>
                </div>
                <div className='flex flex-row z-50 px-2 py-1 items-center gap-1 justify-start w-full'>

                    <p className='text-red-600 text-BodyText/12/Bold'>2X</p>
                    <p className='text-primary-700 text-BodyText/12/Medium'>هدیه رزرو‌شو</p>
                </div>
                <motion.div
                    className=" w-[1px] h-[1px] rounded-[50%] bg-primary-100 bottom-0 -right-[20px] absolute flex z-10 opacity-60"
                    animate={{
                        scale: [1, 320, 1],
                    }}
                    transition={{
                        duration: 5,
                        ease: "backInOut",
                        repeat: Infinity,
                        repeatDelay: 0
                    }}
                />
                <motion.div
                    className=" w-[1px] h-[1px] rounded-[50%] bg-primary-200 bottom-0 -right-[20px] absolute flex z-20 opacity-60"
                    animate={{
                        scale: [1, 220, 1],
                    }}
                    transition={{
                        duration: 4.5,
                        ease: "backInOut",
                        repeat: Infinity,
                        repeatDelay: 0.5
                    }}
                />
                <motion.div
                    className=" w-[1px] h-[1px] rounded-[50%] bg-primary-500 bottom-0 -right-[20px] absolute flex z-30 opacity-60"
                    animate={{
                        scale: [1, 180, 1],
                    }}
                    transition={{
                        duration: 4,
                        ease: "backInOut",
                        repeat: Infinity,
                        repeatDelay: 1
                    }}
                />

                <motion.div
                    className=" w-[1px] h-[1px] rounded-[50%] bg-primary-600 bottom-0 -right-[20px] absolute flex z-40 opacity-60"
                    animate={{
                        scale: [1, 150, 1],
                    }}
                    transition={{
                        duration: 3.5,
                        ease: "anticipate",
                        repeat: Infinity,
                        repeatDelay: 1.5
                    }}
                />
            </div>
    );
};

export default AnimatedBtn;
